import React from "react";
import FooterBar from "../components/footer/FooterBar";



export default function PageNotFound() {
  return (
    <div>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          {/*<h2>We are sorry, Page not found!</h2>
            <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p> */}
          <h2>Nous sommes désolés, Page introuvable!</h2>
          <p>La page que vous recherchez pourrait avoir été supprimée ou est temporairement indisponible.</p>
          <a href="/">Back To Homepage</a>
        </div>
      </div>
      <FooterBar /*isColorBlu={true} color="#008ffb"*/ />
    </div>
  )
}
