import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Footer from '../components/footer/Footer';
import '../stylesheets/w3.css';
import Properties from '../configs/Properties';
import DefaultData from '../configs/DefaultData';
import CloseIcon from '@mui/icons-material/Close';
import SearchAppBar from '../components/SearchAppBar';
import ReactPlayer from 'react-player';
import { IconButton, Backdrop, CircularProgress } from '@mui/material';
import { logout, getEventImage } from '../configs/Services';
import format from 'date-fns/format';
import loadingGif from '../assets/loading.gif';


export default function BirthdayInvitation() {

  const navigate = useNavigate();

  const [displayValue, setDisplayValue] = useState();
  const [wedding, setEvent] = useState();
  const [userLogged, setUserLogged] = useState();
  const [deadlineConfirmation, setDeadlineConfirmation] = useState();
  const [openLoader, setOpenLoader] = React.useState(true);
  const [spousesImage, setSpousesImage] = React.useState(null);


  useEffect(() => {
    if (!displayValue) setDisplayValue('none');
    window.scrollTo(0, 0);
    retrieveData();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retrieveData = async () => {
    const uuidUser = sessionStorage.getItem('uuidUser');
    const uuidEvent = sessionStorage.getItem('uuidEvent');

    // session expired/logout
    if (uuidUser === null || uuidEvent === null) {
      logout(); navigate('/signin');
    }

    if (!userLogged || !wedding) {
      await Promise.all([getUser(uuidUser), getWedding(uuidEvent), getEventImage()]).then(responses => {
        setUserLogged(responses[0]?.data);
        setEvent(responses[1]?.data);
        setSpousesImage(responses[2]);
        setDeadlineConfirmation(new Date(responses[1]?.data.deadlineConfirmInvitation));
        setOpenLoader(false);
      });
    }

  }

  const getUser = (uuid) => {
    return axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + uuid,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => { return response })
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const getWedding = (uuid) => {
    return axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + uuid,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => { return response })
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        }
        console.log(err)
      });
  }

  const updateInvitationStatus = (value) => {
    let data;
    if (value) data.confirmed = true;
    else data.confirmed = false;

    axios.post(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + wedding?.uuid + `/invitation/status`, data,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        },
        params: { guest: userLogged?.uuid }
      }
    )
      .catch((err) => {
        if ((err?.response.status === 401) || (err?.response.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const getMonthName = (date) => {
    let monthName = date?.toLocaleString('fr', { month: 'long' });
    return monthName?.charAt(0).toUpperCase() + monthName?.slice(1);
  }

  const getFormattedDate = (date) => {
    return getMonthName(date) + ", " + new Date(date).getFullYear();
  }

  const handleRSVModal = (e, property) => {
    e.preventDefault();
    document.getElementById('id01').style.display = property;
  }

  const handleScroll = () => {
    if (window.scrollY > 200) setDisplayValue('block');
    else setDisplayValue('none');
  }

  return (
    <div>
      <div className="vertical-center" style={{ backgroundColor: "#fff", justifyContent: "center", display: "flex" }}>
      {openLoader ? <img src={loadingGif} width={90} style={{ position: "fixed", zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 300 }} /> : null}
    </div>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "black" }}
        open={openLoader}
      >
        <CircularProgress className="color-gray" />
      </Backdrop> */}

      <SearchAppBar isGuestInvitationPage={true} wedding={wedding} isUserLogged={true} userLogged={userLogged} />

      {/* Header / Home*/}
      <header className="w3-display-container w3-wide bgimg-event w3-grayscale-min" id="home">
        <div className="w3-display-middle w3-text-white w3-center">
          <h1 className="w3-jumbo font-museo-700" style={{color: "white"}}>{wedding?.eventOwnerFirstName}</h1>
          <h1 className="w3-jumbo font-museo-700" style={{color: "white"}}>{wedding?.eventOwnerLastName}</h1>
          <h2 className="font-museo-700" style={{color: "white"}}>{wedding?.eventName}</h2>
          <h2 className="font-museo-700" style={{color: "white"}}> <b>{wedding?.date && format(new Date(wedding?.date), 'dd.MM.yyyy')} {/*17.07.2017*/}</b> </h2>
        </div>
      </header>

      {/* Navbar (sticky bottom) */}
      <div className="w3-bottom w3-hide-small">
        <div className="w3-bar w3-white w3-center w3-padding w3-opacity-min w3-hover-opacity-off">
          <a href="#home" className="w3-bar-item w3-button">Home</a>
          <a href="#invitation" className="w3-bar-item w3-button">Invitation</a>
          <a href="#details" className="w3-bar-item w3-button">Détails de l'évènement</a>
          <a href="#rsvp" className="w3-bar-item w3-button w3-hover-black">RSVP</a>
        </div>
      </div>

      {/* About / Jane And John */}
      <div className="w3-container w3-padding-top-64 w3-pale-red w3-grayscale-min" id="invitation">
        <div className="w3-content">
          <h1 className="w3-center w3-text-grey mix-theme-color">INVITATION</h1>
          {spousesImage ?
            <img className="w3-round w3-grayscale-min wedding-couple-specs" src={spousesImage} alt="wedding-couple" /> :
            <img className="w3-round w3-grayscale-min wedding-couple-specs" src={require("../assets/birthday_home8.jpg")} alt="wedding-couple" />
          }
          <div className="invitation-text"><i> {wedding?.invitationText} </i></div> <br />
          <div className="invitation-text"><i> {wedding?.giftDetails} </i></div> <br />
          <p className="w3-center"><a href="#details"
            className="w3-button w3-black w3-round w3-padding-large w3-large">Détails de l'évènement</a></p>
        </div>
      </div>

      {
        wedding?.urlVideo ? <>
          <div className="w3-container w3-padding-top-64 w3-pale-red w3-grayscale-min" id="invitation">
            <div className="w3-content">
              <ReactPlayer
                url={wedding?.urlVideo}
                className="w3-round w3-grayscale-min wedding-couple-specs"
                controls={true}
                // width="70%"
                height="80vh"
              />
            </div>
          </div>
        </> : <>
          {/* Background photo */}
          <div className="w3-display-container bgimg2">
            <div className="w3-display-middle w3-text-white w3-center" style={{color: "white"}}>
              <h1 className="w3-jumbo font-museo-700" style={{color: "white"}}>Vous êtes invité</h1><br />
              <h2 className="w3-jumbo font-museo-700" style={{color: "white"}}>Bien sûr..</h2>
            </div>
          </div>
        </>
      }

      {/* Wedding information */}
      <div className="w3-container w3-padding-top-64 w3-padding-bottom-48 w3-pale-red w3-grayscale-min w3-center" id="details">
        <div className="w3-content">
          <h1 className="w3-text-grey mix-theme-color">DÉTAILS DU L'ÉVÉNEMENT</h1>
          <img className="w3-round-large w3-grayscale-min wedding-info-specs" src={require("../assets/Wedding-bouquet-sky3.jpg")} alt="wedding-location" />
          <div className="w3-row">
            <div className="w3-half">
              <h2 className="font-museo-700">Quand</h2>
              <p>Réception - {wedding?.receptionStartime} {/*5:00pm*/}</p>
            </div>
            <div className="w3-half">
              <h2 className="font-museo-700">Où</h2>
              <p>{wedding?.receptionVenue} {/*Some where, some address*/}</p>
            </div>
          </div>
        </div>
      </div>

      {/* RSVP section */}
      <div className="w3-container w3-padding-64 w3-pale-red w3-center" id="rsvp">
        <h1 className="w3-wide mix-theme-color-2" >J’ESPÈRE QUE VOUS Y SEREZ!</h1>
        {deadlineConfirmation && <><p style={{ marginBottom: 30 }}>Veuillez répondre d'ici {getFormattedDate(deadlineConfirmation)} {/*January, 2017*/}</p></> }
        <p>Cliquez sur le bouton ci-dessous pour confirmer votre présence:</p>
        <p className="w3-xlarge">
          <button onClick={(e) => handleRSVModal(e, "block")}
            className="w3-button w3-round w3-red w3-opacity w3-hover-opacity-off rsvp-section-specs" /*style="padding:8px 60px"*/>RSVP</button>
        </p>
      </div>

      {/* RSVP modal */}
      <div id="id01" className="w3-modal">
        <div className="w3-modal-content w3-card-4 w3-animate-zoom rsvp-modal-specs" /*style="padding:32px;max-width:600px"*/>

          <IconButton
            aria-label="close"
            className="align-right"
            color="inherit"
            size="medium"
            onClick={(e) => handleRSVModal(e, "none")}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>

          <div className="w3-container w3-white w3-center">
            <h1 className="font-museo-700">ALLEZ-VOUS Y ASSISTER?</h1>
            <p>Nous espérons vraiment que vous y serez.</p>
            <p>Numéro de table: <strong>{userLogged?.tableNumber}</strong></p>
            <form>
              <input className="w3-input w3-border" type="text" placeholder="Name(s)" name="name" value={userLogged?.firstName + " " + userLogged?.lastName} disabled />
            </form>
            <p><i>Sincèrement, {wedding?.eventOwnerFirstName} {wedding?.eventOwnerLastName}</i></p>
            <div className="w3-row">
              <div className="w3-half">
                <button onClick={(e) => { handleRSVModal(e, "none"); updateInvitationStatus(true) }} type="button"
                  className="w3-button w3-block w3-green">je serai présent</button>
              </div>
              <div className="w3-half">
                <button onClick={(e) => { handleRSVModal(e, "none"); updateInvitationStatus(false) }} type="button"
                  className="w3-button w3-block w3-red">je ne serai pas présent</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <a
        id="gotoTop"
        className="gotoTop page-scroll"
        style={{ 'display': displayValue }}
        href="#home"
      >
        <i
          className="svg-m s123-icon-converter mask-icon-angle"
          data-icon-name="angle-up"
          data-ie11-classes=""
          alt="angle-up"
        >
          &nbsp;
        </i>
      </a>

      <Footer showMenu={false} />

      {/* Footer. This section contains an ad for W3Schools Spaces. You can leave it to support us. */}
      {/* <footer className="w3-center w3-white w3-padding-32">
            <p className="w3-small">Created by Nicy Malanda "MainThread" © 2023</p> */}
      {/* Footer end. */}
      {/* </footer> */}

    </div>);
}

