import React, { useState, useEffect } from "react";
import dayjs from 'dayjs';
import BouncedLoader from "./BouncedLoader";
import { whatsappShare } from '../configs/Services';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Draggable from 'react-draggable';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, DialogTitle, TextField, DialogContent, DialogActions, Dialog, ButtonGroup, DialogContentText, IconButton, Paper } from '@mui/material';



const theme = createTheme({});

const styles = (isError, width, marginRight) => {
  if (isError) {
    return {
      '& label.Mui-error': { color: '#d32f2f !important' },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      width: width,
      marginRight: marginRight
    }
  } else {
    if (width === null && marginRight === null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' }
      }
    } else if (width !== null && marginRight !== null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        width: width,
        marginRight: marginRight
      }
    }
  }
};


export default function DialogGuestManagement(props) {

  const [isOpen, setIsOpen] = useState(true);
  const [firstName, setFirstName] = useState();
  const [username, setUsername] = useState();
  const [lastName, setLastName] = useState();
  const [tableNumber, setTableNumber] = useState();
  const [email, setEmail] = useState();
  const [weddingDate, setEventDate] = useState();
  const [price, setPrice] = useState();
  const [responsibleNewPassword, setResponsibleNewPassword] = React.useState();
  const [phoneNumber, setPhoneNumber] = useState("+243");
  const [language, setLanguage] = useState();
  const [isPwError, setIsPwError] = React.useState(false);
  const [whatsAppButton, setWhatsAppButton] = React.useState(false);
  const [pwErrorMessage, setPwErrorMessage] = React.useState();
  const [totalInvitations, setTotalInvitations] = React.useState(1);


  useEffect(() => {
    setFirstName(props?.inputs[props?.id]?.firstName);
    setLastName(props?.inputs[props?.id]?.lastName);
    setTableNumber(props?.inputs[props?.id]?.tableNumber);
    setEmail(!props?.inputs[props?.id]?.email ? '' : props?.inputs[props?.id]?.email);
    const tmp = props?.inputs[props?.id]?.phoneNumber;
    setPhoneNumber(tmp ? tmp : "+243");
    setLanguage(props?.inputs[props?.id]?.language);
    setUsername(props?.inputs[props?.id]?.username);
    setEventDate(dayjs(new Date(props?.inputs[props?.id]?.date)));
    setPrice(props?.inputs[props?.id]?.price);
    setTotalInvitations(props?.inputs[props?.id]?.totalInvitations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callActionMethod = async (input, id) => {
    props?.setBouncedLoader(true);

    let data = {
      ...input,
      firstName: firstName,
      lastName: lastName,
      tableNumber: tableNumber,
      email: email,
      phoneNumber: phoneNumber,
      username: username,
      date: weddingDate,
      price: price,
      responsibleNewPassword: responsibleNewPassword,
      totalInvitations: totalInvitations
    }
    await props?.actionMethod(data, id);
    if (props?.isEdit && props?.whatsappShareInput) {
      setWhatsAppButton(true);
    }
  }

  const shareInvitation = (text) => whatsappShare(
    {
      text: text,
      wedding: props?.whatsappShareInput?.wedding,
      userLogged: props?.whatsappShareInput?.userLogged,
      invitationUuid: props?.whatsappShareInput?.invitation?.uuid,
      username: props?.whatsappShareInput?.invitation?.username,
      firstName: props?.whatsappShareInput?.invitation?.firstName,
      lastName: props?.whatsappShareInput?.invitation?.lastName,
      totalInvitations: props?.whatsappShareInput?.invitation?.totalInvitations
    }
  );

  const handlePhoneNumber = (phone) => {
    setPhoneNumber(phone);
    // matchIsValidTel(phone) ? setIsPhoneNumberValid(true) : setIsPhoneNumberValid(false);
  }

  const handlePasswordValidation = (passwordInputValue) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
    const digitsPassword = digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);

    setResponsibleNewPassword(String(passwordInputValue).trim());
    setIsPwError(true);

    if (passwordInputValue.length === 0) {
      setPwErrorMessage("Password est vide");
    }
    // else if (!uppercasePassword) {
    //   setPwErrorMessage("Au moins un majuscule");
    // } else if (!lowercasePassword) {
    //   setPwErrorMessage("Au moins un minuscule");
    // } else if (!digitsPassword) {
    //   setPwErrorMessage("Au moins un chiffre");
    // } else if (!specialCharPassword) {
    //   setPwErrorMessage("Au moins un caractère spécial");
    // } 
    else if (!minLengthPassword) {
      setPwErrorMessage("Au moins 8 caractères");
    } else {
      setPwErrorMessage("");
      setIsPwError(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        PaperComponent={PaperComponent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {whatsAppButton ? <>
          <DialogTitle style={{ cursor: 'move', fontWeight: 700, textAlign: 'center', paddingRight: 70, paddingLeft: 70, paddingBottom: 30 }} id="draggable-dialog-title">
            {"Partager dans whatsapp"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => {
              setIsOpen(false);
              props?.handleDialog(props?.id, false);
              props?.getInvitationsPaginated(null, 1)
            }
            }
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </> : <>
          <DialogTitle style={{ cursor: 'move', marginBottom: 15, fontWeight: 700 }} id="draggable-dialog-title">
            {props?.title}
          </DialogTitle>
        </>
        }
        {/* <Divider className="divider" /> */}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props?.content}</DialogContentText>

          {props?.bouncedLoader && <BouncedLoader />}

          {whatsAppButton ? <>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, alignItems: 'center', paddingBottom: 2 }}>
              {shareInvitation("Cliquer ci-dessous")}
            </Box>
          </> : <>
            {props?.isEdit && <>
              <Box component="form" sx={{ "& .MuiTextField-root": { mt: 2 } }} noValidate>
                <Box sx={{ mb: 1 }}>
                  <Box style={{ display: "inline-block", fontSize: 10 }}>
                    Nombre total d’invités (vous y compris)
                  </Box>
                  <Box className="margin-left-enabled" style={{ display: "inline-block" }}>
                    <ButtonGroup size="small" aria-label="small outlined button group">
                      <Button className="button-group" onClick={() => setTotalInvitations(oldvalue => oldvalue === 1 ? oldvalue : oldvalue - 1)}>-</Button>
                      <Button disabled>{totalInvitations}</Button>
                      <Button className="button-group" onClick={() => setTotalInvitations(oldvalue => oldvalue + 1)}>+</Button>
                    </ButtonGroup>
                  </Box>
                </Box>
                <TextField
                  required
                  autoComplete="firstname"
                  className="full-width"
                  sx={styles(!firstName, '49%', '2%')}
                  id="firstname"
                  label="Prénom"
                  error={!firstName}
                  margin="normal"
                  value={String(firstName)}
                  onChange={(e) => setFirstName(String(e.target.value))}
                />
                <TextField
                  required
                  autoComplete="lastname"
                  className="full-width"
                  sx={styles(!lastName, '49%', '0')}
                  margin="normal"
                  id="lastname"
                  label="Nom"
                  error={!lastName}
                  value={String(lastName)}
                  onChange={(e) => setLastName(String(e.target.value))}
                />
                <TextField
                  autoComplete="email"
                  id="email"
                  label="Email"
                  className="full-width"
                  sx={styles(false, '100%', '0')}
                  margin="normal"
                  value={String(email)}
                  onChange={(e) => setEmail(String(e.target.value).trim())}
                />
                {/* <MuiTelInput
                className="full-width"
                sx={styles(false, '49%', '0')}
                value={phoneNumber}
                // error={!isPhoneNumberValid}
                onChange={handlePhoneNumber}
              /> */}
                <TextField
                  autoComplete="username"
                  className="full-width mt-17"
                  sx={styles(false, '49%', '2%')}
                  id="username"
                  label="Identifiant"
                  disabled
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={String(username)}
                  onChange={(e) => setUsername(String(e.target.value).trim())}
                />
                <TextField
                  autoComplete="tableNumber"
                  className="full-width mt-17"
                  sx={styles(false, '49%', '0')}
                  margin="normal"
                  id="tableNumber"
                  type="number"
                  label="Table"
                  value={tableNumber}
                  onChange={(e) => setTableNumber(e.target.value)}
                />
                {/* <FormControl sx={{ mt: 1, width: '100%' }}>
                <InputLabel id="language-label" sx={{ '& inputLabel.Mui-focused': { color: '#03d388 !important' } }}>Langue</InputLabel>
                <Select
                  required
                  className="full-width"
                  sx={styles(false, null, null)}
                  labelId="language-label"
                  id="language"
                  value={String(language)}
                  defaultValue={"FR"}
                  onChange={(e) => setLanguage(e.target.value)}
                  label="Language"
                >
                  <MenuItem value={"FR"}>Français</MenuItem>
                  <MenuItem value={"EN"}>Anglais</MenuItem>
                </Select>
              </FormControl> */}
              </Box>
            </>}
            {props?.isEditResponsible && <>
              <Box component="form" sx={{ "& .MuiTextField-root": { mt: 1, mb: 1 } }} noValidate>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker
                    required
                    className="full-width"
                    sx={styles(false, '100%', '0')}
                    label="Date"
                    fullWidth
                    minDate={dayjs(new Date())}
                    margin="normal"
                    format="DD/MM/YYYY"
                    value={weddingDate}
                    onChange={(newValue) => setEventDate(newValue)}
                  />
                </LocalizationProvider>
                <TextField
                  autoComplete="price"
                  className="full-width"
                  sx={styles(false, '100%', '0')}
                  fullWidth
                  margin="normal"
                  id="price"
                  type="number"
                  label="Prix"
                  InputProps={{ inputProps: { min: 0 } }}
                  defaultValue={0}
                  InputLabelProps={{ shrink: true }}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <TextField
                  autoComplete="Password recovery"
                  className="full-width"
                  sx={styles(isPwError, '100%', '0')}
                  fullWidth
                  type="password"
                  id="Password recovery"
                  label="Password recovery"
                  error={isPwError}
                  margin="normal"
                  value={responsibleNewPassword}
                  onChange={(e) => handlePasswordValidation(e.target.value)}
                  FormHelperTextProps={{ className: "no-margin-left no-letter-space" }}
                  helperText={isPwError &&
                    <div className="OyEIQ uSvLId" jsname="h9d3hd" aria-live="polite">
                      <div className="EjBTad" aria-hidden="true">
                        <svg aria-hidden="true" className="stUf5b LxE1Id" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                        </svg>
                      </div>
                      <div jsname="B34EJ">
                        <span jsslot="">{pwErrorMessage}</span>
                      </div>
                    </div>
                  }
                />
              </Box>
            </>
            }
          </>
          }
        </DialogContent>
        <DialogActions>
          {!whatsAppButton && <>
            <Button sx={{ color: "#03d388" }} onClick={() => { setIsOpen(false); props?.handleDialog(props?.id, false) }}>Annuler</Button>
            <Button
              sx={{ color: "#03d388" }}
              onClick={() => callActionMethod(props?.inputs[props?.id], props?.id)}
              disabled={props?.isEdit ? (!lastName || !firstName || !tableNumber || !language)
                : props.isEditResponsible && (!price && !weddingDate && !responsibleNewPassword)} >Confirmer
            </Button>
          </>}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}



function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}