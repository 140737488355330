import React from "react";
import '../stylesheets/envelope.scss';

export default function Envelope(props) {

  const userLoggedDenomination = sessionStorage.getItem("userLoggedDenomination");
  const denominationFromUrl = String(props?.userLoggedDenomination).replace("_", " ");

  const open = async () => {
    var envelope = document.getElementById("envelope");
    envelope?.classList.remove("close-envelope");
    envelope?.classList.add("open-envelope");
    await delay(8000);
    props?.setOpenEnvelope(false);
  }


  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  )

  return (<>
    <div id="envelope" className="close-envelope" onClick={() => open()}>
      <div className="front flap"></div>
      <div className="front pocket"></div>
      <div className="letter">
        <div className="words line1">Bonjour!</div>
        <div className="words line2">{userLoggedDenomination ? userLoggedDenomination : denominationFromUrl}</div>
        <div className="words line3"></div>
        <div className="words line4"></div>
      </div>
      <div className="hearts">
        <div className="heart a1"></div>
        <div className="heart a2"></div>
        <div className="heart a3"></div>
      </div>
      <div className="reset">
        <button onClick={() => open()}>Ouvrir</button>
        {/* <button onClick={() => close()}>Reset</button> */}
      </div>
    </div>
  </>
  );
}