import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';
import HomeIcon from '@mui/icons-material/Home';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import { logout, Search, SearchIconWrapper, StyledInputBase } from '../configs/Services';
import ProfileAvatar from "./WeinvAvatar";



export default function SearchAppBar(props) {

    const navigate = useNavigate();

    const [anchorElShowMessage, setAnchorElShowMessage] = React.useState(null);
    const [anchorElShowNotification, setAnchorElShowNotification] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [userLoggedDenomination, setUserLoggedDenomination] = React.useState(sessionStorage.getItem('userLoggedDenomination'));

    const isMenuShowMessageOpen = Boolean(anchorElShowMessage);
    const isMenuShowNotificationOpen = Boolean(anchorElShowNotification);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuShowMessageOpen = (e) => {
        setAnchorElShowMessage(e.currentTarget);
    };

    const handleMenuShowMessageClose = () => {
        setAnchorElShowMessage(null);
        sessionStorage.setItem("badge-content-n-messages", 0);
    };

    const handleMenuShowNotificationOpen = (e) => {
        setAnchorElShowNotification(e.currentTarget);
    };

    const handleMenuShowNotificationClose = () => {
        setAnchorElShowNotification(null);
        sessionStorage.setItem("badge-content-n-notifications", 0);
    };

    const handleMenuOpen = (e) => {
        e.preventDefault();
        document.body.classList.add('remove-body-class');
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = () => {
        document.body.classList.remove('remove-body-class');
        setAnchorEl(null);
    };

    const goToDashboard = (e) => {
        e.preventDefault();

        let url;
        const tmp = sessionStorage.getItem('roles');
        const type = sessionStorage.getItem('type');

        if (tmp) {
            const roles = Array.from(tmp);

            if (roles?.some(r => r === 'A')) {
                url = `/admin/dashboard`;
            } else if (roles.some(r => r === 'R')) {
                url = `/profiles/${sessionStorage.getItem('uuidUser')}/dashboard`;
            } else if (roles.some(r => r === 'G')) {
                if (type === 'WEDDING') {
                    url = `/wedding/invitations/${sessionStorage.getItem('uuidUser')}`;
                } else {
                    url = `/event/invitations/${sessionStorage.getItem('uuidUser')}`;
                }
            } else {
                url = `/signin`;
            }
            navigate(url);
        } else {
            navigate(`/signin`);
        }
    }

    const searchInvitation = (e) => {
        e.preventDefault();
        props?.invitationsTableChildRef?.current?.preCallGetData(e.target.value);
    }

    const weinvMenuItem = (text, iconComponent) => {
        return <>
            <MenuItem onClick={handleMenuClose}>
                <IconButton size="small"
                    aria-haspopup="true"
                    color="inherit"
                    aria-label="menu-profile"
                >
                    {iconComponent}
                </IconButton>
                <p>{text}</p>
            </MenuItem>
        </>
    }

    const renderMenu = (
        <Menu
            sx={{ mt: 6 }}
            className="mt-mobile-8"
            anchorEl={anchorEl}
            PaperProps={{
                //style: { width: 130 },
                sx: {
                    overflow: "visible",
                    "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0
                    }
                }
            }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            id="primary-search-account-menu"
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <Link to="/" className="a-none">
                {weinvMenuItem("Home", <HomeIcon fontSize="small" sx={{ color: "#757575" }} />)}
            </Link>
            {props?.isUserLogged ? <>
                <Link to="#" onClick={(e) => goToDashboard(e)} className="a-none">
                    {props?.userLogged?.roles && Array.from(props?.userLogged?.roles).some(r => r === 'G') ? <>
                        {weinvMenuItem("Mon invitation", <DraftsRoundedIcon fontSize="small" sx={{ color: "#757575" }} />)}
                    </> : <>
                        {weinvMenuItem("Mon compte", <AccountCircle fontSize="small" sx={{ color: "#757575" }} />)}
                    </>
                    }
                </Link>
                <Link to="#" onClick={(e) => { logout(e); navigate('/signin'); }} className="a-none">
                    {weinvMenuItem("Déconnexion", <ExitToAppOutlinedIcon fontSize="small" sx={{ color: "#757575" }} />)}
                </Link>
            </> : <>
                <Link to="/signin" className="a-none">
                    {weinvMenuItem("Connexion", <LoginIcon fontSize="small" sx={{ color: "#757575" }} />)}
                </Link>
            </>
            }
        </Menu>
    );

    const renderMenuShowMessage = (
        <Menu
            sx={{ mt: '45px' }}
            anchorEl={anchorElShowMessage}
            PaperProps={{ style: { width: 300, height: 50 } }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            id="menu-show-message"
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={isMenuShowMessageOpen}
            onClose={handleMenuShowMessageClose}
        >
            <MenuItem onClick={handleMenuShowMessageClose}>Vous devez configurer votre invitation!</MenuItem>
        </Menu>
    );

    const renderMenuShowNotification = (
        <Menu
            sx={{ mt: '45px' }}
            anchorEl={anchorElShowNotification}
            PaperProps={{ style: { width: 300, height: 50 } }}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            id="menu-show-message"
            keepMounted
            transformOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            open={isMenuShowNotificationOpen}
            onClose={handleMenuShowNotificationClose}
        >
            <MenuItem onClick={handleMenuShowNotificationClose} sx={{ justifyContent: "center" }}>Bienvenu!</MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" className={props?.isHomePage ? "navbar-fixed" : ""} sx={{ backgroundColor: "#383c44" }}>
                <Toolbar>
                    {/* <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ display: { xs: "none", sm: "block", padding: 10 } }}
                    >
                        <a
                            href="/"
                            data-is-svg="true"
                            className="logo_name page-scroll logoStyle_1 a-none"
                        >
                            <div>
                                <img style={{ background: "transparent", width: "2.5em", height: "2.5em", maxWidth: "none" }} src={require("../assets/wein-logo.png")} alt="weinv-logo" />
                            </div>
                            <span className="website-name" style={{ marginLeft: "5px", fontWeight: "bold" }}>weinv</span>
                        </a>
                    </Typography>
                    {props.isDashboard &&
                        <Search className="css-1olgqbd">
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ "aria-label": "search" }}
                                onChange={(e) => searchInvitation(e)}
                            // onKeyDown={(e) => searchInvitation(e)}
                            />
                        </Search>
                    }
                    <Box sx={{ flexGrow: 1 }} />
                    {props?.isDashboard && <>
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                            <Box sx={{ marginRight: 5 }} >
                                <IconButton
                                    size="large"
                                    aria-label="show 1 new mails"
                                    color="inherit"
                                    onClick={handleMenuShowMessageOpen}
                                >
                                    <Badge badgeContent={sessionStorage.getItem("badge-content-n-messages") == 1 ? 1 : 0} color="error">
                                        <MailIcon />
                                    </Badge>
                                </IconButton>
                                <IconButton
                                    size="large"
                                    aria-label="show 1 new notifications"
                                    color="inherit"
                                    onClick={handleMenuShowNotificationOpen}
                                >
                                    <Badge badgeContent={sessionStorage.getItem("badge-content-n-notifications") == 1 ? 1 : 0} color="error">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Box>
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="more options"
                                aria-controls="primary-search-account-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleMenuOpen(e)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Box sx={{ display: { xs: "flex", md: "none" } }}>
                            <IconButton
                                size="large"
                                aria-label="show more"
                                aria-controls="primary-search-account-menu-mobile"
                                aria-haspopup="true"
                                onClick={(e) => handleMenuOpen(e)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    </>
                    }
                    {(props?.isHomePage || props?.isGuestInvitationPage) && props?.isUserLogged &&
                        <IconButton
                            className="flex-right"
                            size="large"
                            edge="end"
                            aria-label="more options"
                            aria-controls="account-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e)}
                            color="inherit"
                        >
                            <div className="flex justify-center">
                                <div className="">
                                    <ProfileAvatar width="40px" height="40px" name={userLoggedDenomination ? userLoggedDenomination : props?.userLogged?.firstName + ' ' + props?.userLogged?.lastName} />
                                </div>
                            </div>
                            {/* <AccountCircleOutlinedIcon fontSize="small" sx={{ stroke: 'white', strokeWidth: 0 }} /> */}
                            <p className="text-account-logged">{userLoggedDenomination ? userLoggedDenomination : props?.userLogged?.firstName + ' ' + props?.userLogged?.lastName}</p>
                        </IconButton>
                    }
                    {props?.isHomePage && !props?.isUserLogged &&
                        <Box sx={{ marginRight: 5 }} >
                            <div id="top-menu">
                                <ul className="navPages nav navbar-nav" style={{ opacity: 1 }}>
                                    <li className="home-selector active" href="#top-section" >
                                        <a className="page-scroll homepageMenu" href="#page-top">
                                            <span className="txt-container home-color">Home</span>
                                        </a>
                                    </li>
                                    <li className="about-selector" id="5b0a4ba50e4e8" href="#section-5b0a4ba50e4e8" data-menu-module-id="5b0a4ba50e4e8">
                                        <a className="page-scroll" href="#section-5b0a4ba50e4e8">
                                            <span className="txt-container home-color">À propos</span>
                                        </a>
                                    </li>
                                    <li className="weddings-selector" id="5feb3ffb74839" href="#section-5feb3ffb74839" data-menu-module-id="5feb3ffb74839">
                                        <a className="page-scroll" href="#section-5feb3ffb74839">
                                            <span className="txt-container home-color">Événements</span>
                                        </a>
                                    </li>
                                    <li className="contact-selector" id="5b0a4ba521825" href="#section-5b0a4ba521825" data-menu-module-id="5b0a4ba521825">
                                        <a className="page-scroll" href="#section-5b0a4ba521825">
                                            <span className="txt-container home-color">Contact</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Box>
                    }
                    {props?.isHomePage && !props?.isUserLogged &&
                        <IconButton
                            className="flex-right"
                            size="large"
                            edge="end"
                            aria-label="more options"
                            aria-controls="primary-search-account-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleMenuOpen(e)}
                            color="inherit"
                        >
                            {/* {props?.isGuestInvitationPage? <MenuIcon /> : <MoreIcon /> } */}
                            <MenuIcon />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
            {renderMenu}
            {!props?.wedding && renderMenuShowMessage}
            {sessionStorage.getItem('badge-content-n-notifications') == 1 && renderMenuShowNotification}
        </Box>
    );
}
