
import React, { useState } from "react";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


// severity: error, warning, info, success
const WeinvAlert = React.forwardRef((props, ref) => (

  <Alert
    severity={props?.severity}
    {...props}
    ref={ref}
    action={props?.isSnackbarEnabled &&
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={props?.handleClose}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    }
    sx={{justifyContent: props?.justifyContent}}
  >
    {props?.msg}
  </Alert>
));

export default WeinvAlert;