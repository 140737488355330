
import React, { useState } from "react";
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import WeinvAlert from '../components/WeinvAlert';


function transitionRight(props) {
  return <Slide {...props} direction="right" />;
}


export default function SnackbarAlert(props) {

  const handleClose = (e, reason) => {
    if ("clickaway" == reason) return;
    props?.handleClose("");
  };

  return (
    <Snackbar
      anchorOrigin={{
        horizontal: props?.horizontal,
        vertical: props?.vertical,
      }}
      open={true}
      autoHideDuration={5000}
      // message={props?.msg}
      onClose={handleClose}
      TransitionComponent={transitionRight}
      action={
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    >
       <WeinvAlert severity={props?.severity} msg={props?.msg} isSnackbarEnabled={true} handleClose={handleClose} /> 
      
    </Snackbar>
  );
}