import React from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import SnackbarAlert from '../components/WeinvSnackbar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FooterBar from '../components/footer/FooterBar'
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { useNavigate,  useSearchParams } from "react-router-dom";
import Properties from '../configs/Properties'
import LinearProgress from '@mui/material/LinearProgress';
import WeinvAlert from "../components/WeinvAlert";
import { translate, logout } from '../configs/Services'



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link href="">
        2024 All rights reserved - Weinv
      </Link>{' '}
    </Typography>
  );
}

const theme = createTheme();

const styles = (isError) => {
  if (isError) {
    return {
      '& label.Mui-error': { color: '#d32f2f !important' },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' }
    }
  } else {
    return {
      '& label.Mui-focused': { color: '#03d388 !important' },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' }
    }
  }
};


export default function SignIn() {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [username, setUsername] = React.useState(searchParams.get("uk"));
  const [password, setPassword] = React.useState(searchParams.get("pv"));
  const [isPwError, setIsPwError] = React.useState(false); <SnackbarAlert />
  const [isUsernameError, setIsUsernameError] = React.useState(false);
  const [httpErrorMsg, setHttpErrorMsg] = React.useState("");
  const [pwErrorMessage, setPwErrorMessage] = React.useState("");
  const [passwordType, setPasswordType] = React.useState("password");
  const [activeLinearProgress, setActiveLinearProgress] = React.useState(false);


  React.useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ![false]);


  const togglePassword = () => {
    if (passwordType === "password") setPasswordType("text");
    else setPasswordType("password");
  }

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (!username || !password) return;

    setActiveLinearProgress(true);
    setIsUsernameError(false);

    const data = { username: username, password: password };

    await axios.post(Properties.WEINV_BE_API_BASE + `/auth/login`, data, { headers: { "content-type": "application/json", "X-tx-id": uuidv4() } })
      .then(async (response) => {
        // only for showing linear progress
        await delay(4000);
        setActiveLinearProgress(false);

        let url;
        const tmp = response.data?.roles;

        if (tmp) {
          const roles = Array.from(tmp);

          if (roles.some(r => r === 'A')) {
            url = `/admin/dashboard`;
          } else if (roles.some(r => r === 'R')) {
            url = `/profiles/${response.data?.uuidUser}/dashboard`;
          } else if (roles.some(r => r === 'G')) {
            if (response.data?.type === 'WEDDING') {
              url = `/wedding/invitations/${response.data?.uuidUser}`;
            } else {
              url = `/event/invitations/${response.data?.uuidUser}`;
            }
          }

          sessionStorage.setItem('uuidUser', response.data?.uuidUser);
          sessionStorage.setItem('uuidEvent', response.data?.uuidEvent);
          sessionStorage.setItem('access-token', response.data?.access_token);
          sessionStorage.setItem('refresh-token', response.data?.refresh_token);
          sessionStorage.setItem('roles', roles);
          sessionStorage.setItem('type', response.data?.type);
          sessionStorage.setItem('userLoggedDenomination', response?.data?.firstName + ' ' + response?.data?.lastName);

          navigate(url);
        } else {
          navigate(`#error`);
        }

      }).catch(async (err) => {
        // only for showing linear progress
        await delay(4000);
        setActiveLinearProgress(false);
        setHttpErrorMsg(translate(err.response?.data.keyError));
      });
  };

  const handlePasswordValidation = (passwordInputValue) => {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{8,}/;
    const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
    const digitsPassword = digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword = minLengthRegExp.test(passwordInputValue);

    setPassword(String(passwordInputValue).trim());
    setIsPwError(true);

    if (passwordInputValue.length === 0) {
      setPwErrorMessage("Password est vide");
    }
    // else if (!uppercasePassword) {
    //   setPwErrorMessage("Au moins un majuscule");
    // } else if (!lowercasePassword) {
    //   setPwErrorMessage("Au moins un minuscule");
    // } else if (!digitsPassword) {
    //   setPwErrorMessage("Au moins un chiffre");
    // } else if (!specialCharPassword) {
    //   setPwErrorMessage("Au moins un caractère spécial");
    // } 
    else if (!minLengthPassword) {
      setPwErrorMessage("Au moins 8 caractères");
    } else {
      setPwErrorMessage("");
      setIsPwError(false);
    }
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );


  return (
    <div className="hide-scrollbar" >
      <ThemeProvider theme={theme}>
        {activeLinearProgress &&
          <Box sx={{ width: '100%', position: 'absolute', backgroundColor: 'white', '& .MuiLinearProgress-bar': { backgroundColor: '#03BCA1' } }}>
            <LinearProgress />
          </Box>
        }
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 14,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              S'identifier
            </Typography>
            {httpErrorMsg?.length > 0 &&
              <Box sx={{ width: '100%', marginTop: 4, marginBottom: 1 }}>
                <WeinvAlert severity='error' horizontal='right' vertical='bottom' msg={httpErrorMsg} handleClose={setHttpErrorMsg} />
              </Box>
            }
            <Box component="form" onSubmit={(e) => handleSubmit(e)} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Identifiant"
                name="username"
                sx={styles(isUsernameError)}
                autoComplete="username"
                error={isUsernameError}
                autoFocus
                value={username}
                onChange={(e) => setUsername(String(e.target.value).trim())}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                sx={styles(isPwError)}
                type={passwordType}
                id="password"
                FormHelperTextProps={{ className: "no-margin-left no-letter-space" }}
                autoComplete="current-password"
                value={password}
                onChange={(e) => handlePasswordValidation(e.target.value)}
                error={isPwError}
                helperText={!isPwError ? null :
                  <div className="OyEIQ uSvLId" jsname="h9d3hd" aria-live="polite">
                    <div className="EjBTad" aria-hidden="true">
                      <svg aria-hidden="true" className="stUf5b LxE1Id" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                      </svg>
                    </div>
                    <div jsname="B34EJ">
                      <span jsslot="">{pwErrorMessage}</span>
                    </div>
                  </div>
                }
              />
              <FormControlLabel
                control={<Checkbox onClick={() => togglePassword()} value="Afficher le mot de passe" color="primary" />}
                label="Afficher le mot de passe"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                className="button-bg-color gi-btn gi-btn-primary"
                disabled={!(username && password)}
              >
                Connexion
              </Button>
              {/* <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Mot de passe oublié?
                    </Link>
                  </Grid>
                 <Grid item>
                <Link href="#" variant="body2">
                  {"Vous n'avez pas de compte? Inscrivez-vous"}
                </Link>
              </Grid> 
                </Grid> */}
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
        <FooterBar /*isColorBlu={true} color="#03d388"*/ />
      </ThemeProvider>
    </div>
  );
}