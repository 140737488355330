import React from "react";
import { useNavigate } from "react-router-dom";

export default function FooterBar(props) {
  const navigate = useNavigate();

  return (
    <div id="showSmallAdOnScroll" className="upgrade-website-preview-helper style2 static" style={{ borderTopColor: "#03d388" }} >
      <a rel="nofollow" target="_blank">
        <span className="site123link site123link-green" style={{ cursor: 'pointer', color: "#03d388" }} onClick={() => navigate('/')}>WEINV</span>
      </a>
    </div>
  );
}

