import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import ResponsibleDashboard from './pages/dashboard/ResponsibleDashboard';
import AdminDashboard from './pages/dashboard/AdminDashboard';
import reportWebVitals from './reportWebVitals';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import WeddingInvitation from './pages/WeddingInvitation';
import WeddingCardInvitation from './pages/WeddingCardInvitation';
import BirthdayInvitation from './pages/BirthdayInvitation';
import Profile from "./pages/Profile";
import BirthdaySettings from "./pages/BirthdaySettings";
import WeddingSettings from "./pages/WeddingSettings";
import PageNotFound from "./pages/PageNotFound";
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <SnackbarProvider maxSnack={3}>
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/signin" element={<SignIn />} />
      {/*  <Route exact path="/signup" element={<SignUp />} />  */}
        <Route exact path="/profiles/:uuid/dashboard" element={<ResponsibleDashboard />} />
        <Route exact path="/profiles/:uuid/infos" element={<Profile />} />
        <Route exact path="/profiles/:uuid/wedding-settings" element={<WeddingSettings />} />
        <Route exact path="/profiles/:uuid/settings" element={<BirthdaySettings />} />
        <Route exact path="/admin/dashboard" element={<AdminDashboard />} />
        <Route exact path="/wedding/invitations/:uuid" element={<WeddingInvitation />} />
        <Route exact path="/wedding/invitations/:uuid/card" element={<WeddingCardInvitation />} />
        <Route exact path="/event/invitations/:uuid" element={<BirthdayInvitation />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// eslint-disable-next-line
