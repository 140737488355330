import React from "react";
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Draggable from 'react-draggable';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import Properties from '../configs/Properties';
import { translate, logout, whatsappShare } from '../configs/Services';
import { useNavigate } from "react-router-dom";
import WeinvAlert from '../components/WeinvAlert';
import { useParams } from "react-router-dom";
import loadingGif from '../assets/loading.gif';
import BouncedLoader from "./BouncedLoader";
import { Box, Button, DialogTitle, TextField, DialogContent, DialogActions, Dialog, Select, InputLabel, FormControl, MenuItem, InputAdornment, IconButton, Paper, ButtonGroup } from '@mui/material';



const theme = createTheme({});

const styles = (isError, width, marginRight) => {
  if (isError) {
    return {
      '& label.Mui-error': { color: '#d32f2f !important' },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      width: width,
      marginRight: marginRight
    }
  } else {
    if (width === null && marginRight === null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' }
      }
    } else if (width !== null && marginRight !== null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        width: width,
        marginRight: marginRight
      }
    }
  }
};


export default function DialogInviteGuest(props) {

  const navigate = useNavigate();
  const params = useParams();
  const [openLoader, setOpenLoader] = React.useState(false);
  const [bouncedLoader, setBouncedLoader] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState();
  const [httpErrorMsg, setHttpErrorMsg] = React.useState();
  const [firstName, setFirstName] = React.useState();
  const [username, setUsername] = React.useState();
  const [lastName, setLastName] = React.useState();
  const [price, setPrice] = React.useState();
  const [tableNumber, setTableNumber] = React.useState();
  const [email, setEmail] = React.useState();
  const [phoneNumber, setPhoneNumber] = React.useState("+243");
  const [type, setType] = React.useState("WEDDING");
  const [isPwError, setIsPwError] = React.useState(false);
  const [isConfirmPwError, setIsConfirmPwError] = React.useState(false);
  const [password, setPassword] = React.useState();
  const [confirmPassword, setConfirmPassword] = React.useState();
  const [pwErrorMessage, setPwErrorMessage] = React.useState();
  const [passwordType, setPasswordType] = React.useState('password');
  const [showPassword, setShowPassword] = React.useState();
  const [confirmPasswordType, setConfirmPasswordType] = React.useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = React.useState();
  const [whatsAppButton, setWhatsAppButton] = React.useState(false);
  const [totalInvitations, setTotalInvitations] = React.useState(1);


  const shareInvitation = (text) => whatsappShare(
    {
      text: text,
      wedding: props?.wedding,
      invitationUuid: params?.uuid,
      username: username,
      password: password,
      firstName: firstName,
      lastName: lastName,
      isForResponsible: props?.isForResponsible,
      userLogged: props?.userLogged,
      totalInvitations: totalInvitations
    }
  );

  const registerUser = async (e) => {
    e.preventDefault();
    setHttpErrorMsg();
    //setOpenLoader(true);
    setBouncedLoader(true);
    let data = {
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      type: type,
      password: password,
      totalInvitations: totalInvitations
    };

    let url = Properties.WEINV_USERS_PATH_BASE + `register`;
    let successMsg = 'Responsable du mariage crée avec succès';
    if (!props?.isForResponsible) {
      url = Properties.WEINV_EVENTS_PATH_BASE + props?.wedding?.uuid + `/invite`;
      data.tableNumber = tableNumber;
      successMsg = 'Invitation envoyée avec succès';
    } else {
      data.price = price;
    }

    await axios.post(Properties.WEINV_BE_API_BASE + url, data,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      }
    ).then(async () => {
      setSuccessMsg(successMsg);
      setHttpErrorMsg();
      setWhatsAppButton(true);
      // only for showing success message for 5 seconds
      //await delay(700);
      //setOpenLoader(false);
      setBouncedLoader(false);
      await delay(4000);

      if (props?.isForResponsible) props?.weddingsTableChildRef?.current?.preCallGetData(null, null, 1);
      else props?.invitationsTableChildRef?.current?.preCallGetData();
    }
    ).catch(async (err) => {
      if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
        setHttpErrorMsg('Session expirée');
        logout(); navigate('/signin');
      } else if (err?.response?.status === 500) {
        setHttpErrorMsg(translate(""));
      } else setHttpErrorMsg(translate(err.response?.data.keyError));

      setSuccessMsg();

      //await delay(1000);
      //setOpenLoader(false);
      setBouncedLoader(false);
    });
  }

  const handlePasswordValidation = (type, passwordInputValue) => {
    if (type === 'confirmPassword') {
      setConfirmPassword(passwordInputValue);
      if (passwordInputValue !== password) setIsConfirmPwError(true);
      else setIsConfirmPwError(false);

    }

    if (type === 'password') {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);

      setPassword(String(passwordInputValue).trim());
      setIsPwError(true);

      if (passwordInputValue.length === 0) {
        setPwErrorMessage("Password est vide");
      }
      // else if (!uppercasePassword) {
      //   setPwErrorMessage("Au moins un majuscule");
      // } else if (!lowercasePassword) {
      //   setPwErrorMessage("Au moins un minuscule");
      // } else if (!digitsPassword) {
      //   setPwErrorMessage("Au moins un chiffre");
      // } else if (!specialCharPassword) {
      //   setPwErrorMessage("Au moins un caractère spécial");
      // } 
      else if (!minLengthPassword) {
        setPwErrorMessage("Au moins 8 caractères");
      } else {
        setPwErrorMessage("");
        setIsPwError(false);
      }
    }
  }

  const handleShowPassword = (value) => {
    if (value === 'password') {
      showPassword ? setPasswordType('password') : setPasswordType('text');
      setShowPassword(v => !v);
    }
    if (value === 'confirmPassword') {
      showConfirmPassword ? setConfirmPasswordType('password') : setConfirmPasswordType('text');
      setShowConfirmPassword(v => !v);
    }
  }

  const handlePhoneNumber = (phone) => {
    setPhoneNumber(phone);
    // matchIsValidTel(phone) ? setIsPhoneNumberValid(true) : setIsPhoneNumberValid(false);
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleTotalInvitations = (value) => {
    if (value > 1) setFirstName("Couple");
    else if (value === 1) setFirstName(undefined);
    setTotalInvitations(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={props.openInviteGuestDialog}
        PaperComponent={PaperComponent}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        {whatsAppButton ? <>
          <DialogTitle style={{ cursor: 'move', fontWeight: 700, textAlign: 'center', paddingRight: 70, paddingLeft: 70, paddingBottom: 30 }} id="draggable-dialog-title">
            {"Partager dans whatsapp"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => { props?.setOpenInviteGuestDialog(false); setWhatsAppButton(false); }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </> : <>
          <DialogTitle style={{ cursor: 'move', fontWeight: 700 }} id="draggable-dialog-title">
            {props?.title}
          </DialogTitle>
        </>
        }

        <DialogContent>
          {httpErrorMsg &&
            <Box sx={{ marginTop: 1, marginBottom: 2 }}>
              <WeinvAlert severity='error' horizontal='right' vertical='bottom' msg={httpErrorMsg} handleClose={setHttpErrorMsg} />
            </Box>
          }
          {successMsg &&
            <Box sx={{ marginTop: 1, marginBottom: 2 }}>
              <WeinvAlert severity='success' horizontal='right' vertical='bottom' justifyContent='center' msg={successMsg} handleClose={setSuccessMsg} />
            </Box>
          }
          <div className="vertical-center" style={{ backgroundColor: "#fff", justifyContent: "center", display: "flex" }}>
            {openLoader ? <img src={loadingGif} alt="" width={90} style={{ position: "fixed", zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 300 }} /> : null}
          </div>

          {bouncedLoader && <BouncedLoader />}

          {whatsAppButton ? <>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, alignItems: 'center', paddingBottom: 2 }}>
              {shareInvitation("Cliquer ci-dessous")}
            </Box>
          </> : <>
            {props?.isForResponsible ? <></> :
              <Box sx={{ mt: 2 }}>
                <Box style={{ display: "inline-block", fontSize: 10 }}>
                  Nombre total d’invités (vous y compris)
                </Box>
                <Box className="margin-left-enabled" style={{ display: "inline-block" }}>
                  <ButtonGroup size="small" aria-label="small outlined button group">
                    <Button className="button-group" onClick={() => handleTotalInvitations(totalInvitations === 1 ? totalInvitations : totalInvitations - 1)}>-</Button>
                    <Button disabled>{totalInvitations}</Button>
                    <Button className="button-group" onClick={() => handleTotalInvitations(totalInvitations + 1)}>+</Button>
                  </ButtonGroup>
                </Box>
              </Box>
            }

            <Box component="form" sx={{ "& .MuiTextField-root": { mt: 2 } }} noValidate>
              <TextField
                required
                autoComplete={"firstname"}
                className="full-width"
                sx={styles(firstName?.length === 0, '49%', '2%')}
                id={"firstname"}
                label={"Prénom"}
                disabled={totalInvitations === 1 ? false : true}
                error={firstName?.length === 0}
                margin="normal"
                value={firstName}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setFirstName(String(e.target.value))}
              />
              <TextField
                required
                autoComplete={"lastname"}
                className="full-width"
                sx={styles(lastName?.length === 0, '49%', '0')}
                margin="normal"
                id={"lastname"}
                label={"Nom"}
                error={lastName?.length === 0}
                value={lastName}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setLastName(String(e.target.value))}
              />
              <TextField
                autoComplete="email"
                id="email"
                label="Email"
                className="full-width"
                sx={styles(false, '100%', '0')}
                margin="normal"
                value={email}
                onChange={(e) => setEmail(String(e.target.value).trim())}
              />
              {/* <MuiTelInput
                className="full-width mb-2"
                sx={styles(false, '49%', '0')}
                value={phoneNumber}
                // error={!isPhoneNumberValid}
                onChange={handlePhoneNumber}
              /> */}
              <TextField
                required
                className="full-width"
                sx={styles(username?.length === 0, '49%', '2%')}
                margin="normal"
                id="username"
                label="Identifiant"
                error={username?.length === 0}
                value={username}
                onChange={(e) => setUsername(String(e.target.value).trim(), username)}
              />
              {props?.isForResponsible ? <>
                <TextField
                  autoComplete="price"
                  className="full-width"
                  sx={styles(false, '49%', '0')}
                  margin="normal"
                  id="price"
                  type="number"
                  label="Prix"
                  InputProps={{ inputProps: { min: 0 } }}
                  defaultValue={0}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <FormControl sx={{ mt: 2, width: '100%' }}>
                  <InputLabel id="type-label" sx={{ '& inputLabel.Mui-focused': { color: '#03d388 !important' } }}>Type d'événement</InputLabel>
                  <Select
                    required
                    sx={styles(false, null, null)}
                    labelId="type-label"
                    id="type"
                    label="Type d'événement"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <MenuItem value={"WEDDING"}>Mariage</MenuItem>
                    <MenuItem value={"BIRTHDAY"}>Anniversaire</MenuItem>
                  </Select>
                </FormControl>
              </> : <>
                <TextField
                  autoComplete="tableNumber"
                  className="full-width"
                  sx={styles(false, '49%', '0')}
                  margin="normal"
                  id="tableNumber"
                  type="number"
                  label="Table"
                  value={tableNumber}
                  onChange={(e) => setTableNumber(e.target.value)}
                /></>
              }
              {/* <FormControl sx={{ mt: 1, width: '100%' }}>
              <InputLabel id="language-label" sx={{ '& inputLabel.Mui-focused': { color: '#03d388 !important' } }}>Langue</InputLabel>
              <Select
                required
                sx={styles(false, null, null)}
                labelId="language-label"
                id="language"
                label="Langue"
                value={language}
                defaultValue={"FR"}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <MenuItem value={"FR"}>Français</MenuItem>
                <MenuItem value={"EN"}>Anglais</MenuItem>
              </Select>
            </FormControl> */}
              {props?.isForResponsible ? <>
                <Box sx={{ mt: 1 }} >
                  <TextField
                    required
                    className="full-width"
                    sx={styles(isPwError, '49%', '2%')}
                    type={passwordType}
                    id="password"
                    label="Mot de pass"
                    error={isPwError}
                    margin="normal"
                    value={password}
                    onChange={(e) => handlePasswordValidation('password', e.target.value)}
                    FormHelperTextProps={{ className: "no-margin-left no-letter-space" }}
                    helperText={isPwError &&
                      <div className="OyEIQ uSvLId" jsname="h9d3hd" aria-live="polite">
                        <div className="EjBTad" aria-hidden="true">
                          <svg aria-hidden="true" className="stUf5b LxE1Id" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                          </svg>
                        </div>
                        <div jsname="B34EJ">
                          <span jsslot="">{pwErrorMessage}</span>
                        </div>
                      </div>
                    }
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleShowPassword('password')}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end">
                            {!showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                  <TextField
                    required
                    className="full-width"
                    sx={styles(isConfirmPwError, '49%', '0')}
                    type={confirmPasswordType}
                    id="confirmPassword"
                    label="Répéter mot de passe"
                    error={isConfirmPwError}
                    margin="normal"
                    value={confirmPassword}
                    onChange={(e) => handlePasswordValidation('confirmPassword', e.target.value)}
                    FormHelperTextProps={{ className: "no-margin-left no-letter-space" }}
                    helperText={isConfirmPwError &&
                      <div className="OyEIQ uSvLId" jsname="h9d3hd" aria-live="polite">
                        <div className="EjBTad" aria-hidden="true">
                          <svg aria-hidden="true" className="stUf5b LxE1Id" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                          </svg>
                        </div>
                        <div jsname="B34EJ">
                          <span jsslot="">Les mots de passe ne correspondent pas</span>
                        </div>
                      </div>
                    }
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleShowPassword('confirmPassword')}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end">
                            {!showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                </Box>
              </> : <></>
              }
            </Box>
          </>}
        </DialogContent>

        {!whatsAppButton && <>
          <DialogActions>
            <Button sx={{ color: "#03d388" }} onClick={() => { props?.setOpenInviteGuestDialog(false); setWhatsAppButton(false); }}>Annuler</Button>
            <Button
              onClick={(e) => registerUser(e)}
              sx={{ color: "#03d388" }}
              autoFocus
              disabled={props?.isForResponsible ?
                !(lastName && firstName && username && !isPwError && !isConfirmPwError && password && confirmPassword) || bouncedLoader
                : !(lastName && firstName && username) || bouncedLoader} > {props?.btnAction}
            </Button>
          </DialogActions>
        </>}
      </Dialog>
    </ThemeProvider>
  );
}



function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}