import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function HomeSection() {

  const navigate = useNavigate();

  const goTo = (e) => {
    e.preventDefault();

    let url;
    const tmp = sessionStorage.getItem('roles');
    const type = sessionStorage.getItem('type');

    if (tmp) {
      const roles = Array.from(tmp);

      if (roles?.some(r => r === 'A')) {
        url = `/admin/dashboard`;
      } else if (roles.some(r => r === 'R')) {
        url = `/profiles/${sessionStorage.getItem('uuidUser')}/dashboard`;
      } else if (roles.some(r => r === 'G')) {
        if (type === 'WEDDING') {
          url = `/wedding/invitations/${sessionStorage.getItem('uuidUser')}`;
        } else {
          url = `/event/invitations/${sessionStorage.getItem('uuidUser')}`;
        }
      } else {
        url = `/signin`;
      }
      navigate(url);
    } else {
      navigate(`/signin`);
    }

  }

  return (
    <section
      id="top-section"
      className="homepage_style_1 top_magic_homepage_kind_1 parallax-on old_homepage_layout_log old_homepage_layout_structure_log top-section"
    >
      <div className="home_background_wrapper parallax-on">
        <div
          className="parallax-window home-image-bg home-bg-parallax"
          data-parallax="scroll"
          data-bleed="0"
          data-idele="parallax_home_opacity"
          data-opacity="0.5"
          data-filter=""
          data-backgroundcolor="#000000"
          data-image-src={require("../../assets/home-img.jpg")}
          data-position="center center"
          data-background-position="center center"
          data-jarallax-original-styles="background-position: center center; filter: none;"
        >
          <div
            id="jarallax-container-0"
            className="jarallax-container jarallax-style"
          >
            <div className="jarallax-style-img"
            ></div>
          </div>
        </div>
      </div>
      <div className="magic_homepage magic_homepage_kind_simple magic_homepage_kind_1 homepage-layout-24 container parent_simple_object_position_center_center">
        <div
          className="home_main_wrapper main_ele_place_bottom simple_object_position_center_center"
          data-main-goal="buttons"
          data-second-goal="no"
        >
          <div className="box_container boxStyle_">
            <div className="hm-t-c boxBorder ele_align_center">
              <h1 id="home_siteSlogan" className="weight700 shadow1 hm-txt">
                C'est le grand événement{/* It's The Great Wedding */}
              </h1>
              <h2
                id="home_siteSlogan_2"
                className="weight400 shadow1 hm-txt"
                style={{ display: "none" }}
              ></h2>
              <p
                id="home_SecondSiteSlogan"
                className="weight400 shadow1 background9 hm-txt"
              >
                Plus qu'un simple événement{/* More Than Just an Wedding */}
              </p>
            </div>
            <div
              className="homepage_goal space_from_bottom_mainGoal ele_align_center mainGoal homepage-goal-specs"
              data-type="buttons"
            >
              <div className="promoButtons align_center" >
                <Link to="" style={{ textDecoration: 'none' }} >
                  <a
                    id="home_buttonText"
                    className="btn btn-xl w-helper"
                    // onClick="MoveFirstSection(1);"
                    data-w-helper-filter="buttons"
                    aria-label="Start here"
                    style={{color: "white", backgroundColor: "#00adb5"}}
                    onClick={(e) => goTo(e)}
                  >
                    <span className="h-b-container">
                      <span className="h-b-t">
                        <span className="h-b-t">Connexion</span>
                      </span>
                    </span>
                  </a>
                </Link>
                <a
                  id="home_buttonText_1"
                  className="btn btn-primary btn-xl w-helper"
                  style={{ display: "none" }}
                  onClick="MoveFirstSection(1);"
                  data-w-helper-filter="buttons"
                  aria-label=""
                >
                  <span className="h-b-container">
                    <span className="h-b-t">
                      <span className="h-b-t"></span>
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}