import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';


const theme = createTheme({});


export default function WeinvDialog(props) {

  const onClickConfirm = () => {
    props?.onClickConfirm();
    props?.setOpenDialog(false);
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={props?.openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: !props?.screenshot ? {} : {
            minHeight: '80%',
            maxHeight: '80%',
            minWidth: '80%',
            maxWidth: '80%'
          }
        }}
      >
        {props?.title && <>
          <DialogTitle className="font-montserrat" >
            {props?.title}
          </DialogTitle>
          <Divider sx={{ maxWidth: "100%" }} />
        </>
        }

        <DialogContent>
          <DialogContentText className="font-montserrat color-dark-gray" sx={{ fontSize: 16 }}>{props?.content}</DialogContentText>
        </DialogContent>
        <DialogActions className="font-montserrat justify-content-center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => onClickConfirm()}>
            {/*Ok*/}{props?.buttonConfirmationText}
          </Button>

          {!props?.screenshot &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => props?.setOpenDialog(false)}>
              {props?.buttonAnnulationtionText}
            </Button>
          }
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}