import React from "react";
import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';



export default function WeddingCardInvitation() {
  const params = useParams();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
      <Box sx={{marginTop: 5}}>
      <img src={require('../assets/' + params.uuid + ".jpg")} width={450} />
      </Box>
    </Box>
  );
}

