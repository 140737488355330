import React from "react";

export default function Footer(props) {

  return (
    <footer className="global_footer footer_2" style={{ fontWeight: 500 }}>
      <div className="part1">
        {
          !props.showMenu ? null :
            <div className="hidden-xs">
              <ul className="navPages nav navbar-nav" style={{ opacity: 1 }}>
                <li className="moduleMenu home-selector active" href="#top-section">
                  <a className="page-scroll homepageMenu" href="#page-top">
                    <span className="txt-container home-color">Home</span>
                  </a>
                </li><li className="moduleMenu about-selector" data-menu-module-id="5b0a4ba50e4e8" id="5b0a4ba50e4e8" href="#section-5b0a4ba50e4e8">
                  <a className="page-scroll" href="#section-5b0a4ba50e4e8">
                    <span className="txt-container home-color">À propos</span>
                  </a>
                </li><li className="moduleMenu weddings-selector" data-menu-module-id="5feb3ffb74839" id="5feb3ffb74839" href="#section-5feb3ffb74839">
                  <a className="page-scroll" href="#section-5feb3ffb74839">
                    <span className="txt-container home-color">Événements</span>
                  </a>
                </li><li className="moduleMenu contact-selector" data-menu-module-id="5b0a4ba521825" id="5b0a4ba521825" href="#section-5b0a4ba521825">
                  <a className="page-scroll" href="#section-5b0a4ba521825">
                    <span className="txt-container home-color">Contact</span>
                  </a>
                </li>
              </ul>
            </div>
        }
        <div>
          Copyright © 2024 All rights reserved -
          <span className="website-name-preview-helper"> Weinv</span>
        </div>
        <div className="clearfix upgrade-website-preview-helper">
          by nicy m. walesa <span className="base-color">"The mainthread"</span>
        </div>
      </div>
    </footer>
  );
}

