import React from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Properties from '../configs/Properties';
import { translate, logout } from '../configs/Services';
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Tooltip from '@mui/material/Tooltip';
import DialogGuestManagement from "./DialogGuestManagement";
import Button from '@mui/material/Button';
import fileDownload from 'js-file-download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import WeinvSnackbar from './WeinvSnackbar';
import InvitationStatus from './model/InvitationStatus';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import loadingGif from '../assets/loading.gif';
import BouncedLoader from "./BouncedLoader";




function InvitationsTable(props, ref) {

  const invitationPageSize = 12;
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [invitationstotalPages, setInvitationstotalPages] = React.useState();
  const [openEditDialog, setOpenEditDialog] = React.useState([false]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState([false]);
  const [bouncedLoader, setBouncedLoader] = React.useState(false);
  const [httpErrorMsg, setHttpErrorMsg] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");
  const [totalAttending, setTotalAttending] = React.useState(0);
  const [totalNotAttending, setTotalNotAttending] = React.useState(0);
  const [totalMaybe, setTotalMaybe] = React.useState(0);
  const [totalNotReplied, setTotalNotReplied] = React.useState(0);
  const [currentInvitationStatus, setCurrentInvitationStatus] = React.useState(InvitationStatus.ATTENDING.name);


  React.useEffect(() => {
    getInvitationsPaginated(null, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  React.useImperativeHandle(ref, () => ({
    preCallGetData,
  }));

  const preCallGetData = (searchKeyword) => {
    getInvitations(searchKeyword, 1, invitationPageSize, currentInvitationStatus);
  }

  const getInvitationsByStatus = (e, status) => {
    e.preventDefault();
    setCurrentInvitationStatus(status);
    document.querySelectorAll(".selected")?.forEach((element) => element?.classList?.remove("selected"));
    e?.target?.classList?.add("selected");
    getInvitations(null, 1, invitationPageSize, status);
  }

  const getInvitationsPaginated = (e, offset) => {
    getInvitations(null, offset, invitationPageSize, currentInvitationStatus);
  }

  const getInvitations = async (searchKeyword, offset, invitationPageSize, status) => {
    if (!props?.openLoaderParent) {
      props?.setOpenLoaderGuestTable(true);
    }

    const uuidEvent = sessionStorage.getItem('uuidEvent');

    await axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + uuidEvent + `/invitations`,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        },
        params: {
          offset: (offset - 1),
          limit: invitationPageSize,
          searchKeyword: searchKeyword,
          status: status
        }
      })
      .then((response) => {
        const size = response.data?.content?.length;
        props?.setInvitations(response.data.content);
        setInvitationstotalPages(response.data.totalPages);
        setTotalAttending(response.data.totalGuestsAttending);
        setTotalNotAttending(response.data.totalGuestsNotAttending);
        setTotalMaybe(response.data.totalGuestsMaybe);
        setTotalNotReplied(response.data.totalGuestsNotReplied);
        setPage(offset);
        setOpenEditDialog(Array(size).fill(false));
        setOpenDeleteDialog(Array(size).fill(false));
        props?.setOpenLoaderGuestTable(false);
      })
      .catch((err) => {
        if ((err?.response.status === 401) || (err?.response.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const updateInvitation = async (userInvitation, index) => {
    let data = {
      firstName: userInvitation.firstName,
      lastName: userInvitation.lastName,
      tableNumber: userInvitation.tableNumber,
      email: userInvitation.email,
      phoneNumber: userInvitation.phoneNumber,
      totalInvitations: userInvitation.totalInvitations
    };
    await axios.patch(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + userInvitation?.uuid, data,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        },
        params: { uuidEvent: userInvitation?.uuidEvent }
      }
    )
      .then(async (response) => {
        setBouncedLoader(false);
        setSuccessMsg('Invitation modifié avec succès');
      })
      .catch((err) => {
        if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
          setHttpErrorMsg('Session expirée');
          logout(); 
          navigate('/signin');
        } else {
          setBouncedLoader(false);
          setHttpErrorMsg(translate(err.response?.data.keyError));
          handleOpenEditDialog(index, false);
        }
      });
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handleOpenEditDialog = (index, value) => {
    openEditDialog[index] = value;
    setOpenEditDialog([...openEditDialog.slice(0, index), value, ...openEditDialog.slice(index + 1)]);
  };

  const handleOpenDeleteDialog = (index, value) => {
    openDeleteDialog[index] = value;
    setOpenDeleteDialog([...openDeleteDialog.slice(0, index), value, ...openDeleteDialog.slice(index + 1)])
  };

  const deleteInvitation = async (userInvitation, index) => {
    await axios.delete(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + userInvitation?.uuid,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        },
        params: { uuidEvent: userInvitation?.uuidEvent }
      }
    )
      .then(response => {
        setBouncedLoader(false);
        setSuccessMsg('Invitation supprimée avec succès');
        getInvitationsPaginated(null, 1);
      })
      .catch((err) => {
        if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
          setHttpErrorMsg('Session expirée');
          logout(); navigate('/signin');
        } else {
          setHttpErrorMsg(translate(err.response?.data.keyError));
        }
        setBouncedLoader(false);
      });

    handleOpenDeleteDialog(index, false);
  }

  const handleDownload = () => {
    const uuidEvent = sessionStorage.getItem('uuidEvent');
    axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + uuidEvent + `/downloadPdf`,
      {
        responseType: 'blob',
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then(response => fileDownload(response.data, `weinv_invitations.pdf`))
      .catch((err) => {
        if ((err?.response.status === 401) || (err?.response.status === 403)) {
          logout(); navigate('/signin');
        } else {
          setHttpErrorMsg(translate("not found resource wedding"));
        }
      })
  };

  const selectEventsByStatus = (e) => {
    e.preventDefault();
    document.getElementById("select2-container--open")?.classList?.toggle("select2-container--open");
    document.getElementById("select2-results")?.classList?.toggle("select2-results");
    document.getElementById("list-wrap-size-sm")?.classList?.toggle("menu-over");
    document.getElementById("empty-msg")?.classList?.toggle("display-empty-msg");
  }

  const displayEmptyMsg = () => {
    switch (currentInvitationStatus) {
      case InvitationStatus.ATTENDING.name: return "Une liste des invités présents apparaîtra ici.";
      case InvitationStatus.NOT_ATTENDING.name: return "Une liste des invités qui ne peuvent pas le faire apparaîtra ici.";
      case InvitationStatus.MAYBE.name: return "Une liste des invités qui ne sont pas encore sûrs apparaîtra ici.";
      case InvitationStatus.NOT_REPLIED.name: return "Une liste des invités qui n’ont pas répondu apparaîtra ici.";
    }
  }

  const getCurrentTotalElements = () => {
    switch (currentInvitationStatus) {
      case InvitationStatus.ATTENDING.name: return totalAttending;
      case InvitationStatus.NOT_ATTENDING.name: return totalNotAttending;
      case InvitationStatus.MAYBE.name: return totalMaybe;
      case InvitationStatus.NOT_REPLIED.name: return totalNotReplied;
    }
  }

  const getcurrentStatus = () => {
    switch (currentInvitationStatus) {
      case InvitationStatus.ATTENDING.name: return InvitationStatus.ATTENDING.description;
      case InvitationStatus.NOT_ATTENDING.name: return InvitationStatus.NOT_ATTENDING.description;
      case InvitationStatus.MAYBE.name: return InvitationStatus.MAYBE.description;
      case InvitationStatus.NOT_REPLIED.name: return InvitationStatus.NOT_REPLIED.description;
    }
  }

  const buildGuestDetailsHtmlElement = (invitation, index) => {
    return <>
      <a href="#" onClick={() => handleOpenEditDialog(index, true)}>
        <Tooltip title={invitation.firstName + " " + invitation.lastName}>
          <span>{invitation.firstName} {invitation.lastName}</span>
        </Tooltip>
      </a>
      <div style={{ display: "inline-block", float: "right" }}>
        <span style={{ marginRight: 20 }}>({invitation.totalInvitations})</span>
        <EditNoteOutlinedIcon style={{ color: 'rgb(134 134 134)', marginRight: 5, cursor: "pointer" }} onClick={() => handleOpenEditDialog(index, true)} />
        <DeleteOutlinedIcon style={{ color: 'rgb(134 134 134)', cursor: "pointer" }} onClick={() => handleOpenDeleteDialog(index, true)} />
      </div>

      {!openEditDialog[index] ? null :
        <DialogGuestManagement
          id={index}
          title="Modifier l'invitation"
          actionMethod={updateInvitation}
          inputs={props?.invitations}
          setInputs={props?.setInvitations}
          handleDialog={handleOpenEditDialog}
          isEdit={true}
          bouncedLoader={bouncedLoader}
          setBouncedLoader={setBouncedLoader}
          getInvitationsPaginated = {getInvitationsPaginated}
          whatsappShareInput={{
            wedding: props?.wedding,
            userLogged: props?.userLogged,
            invitation: invitation
          }}
        />
      }
      {!openDeleteDialog[index] ? null :
        <DialogGuestManagement
          id={index}
          title="Supprimer l'invitation"
          content={"Êtes-vous sûr de vouloir supprimer cette invitation (" + invitation.firstName + " " + invitation.lastName + ") ?"}
          actionMethod={deleteInvitation}
          inputs={props?.invitations}
          setInputs={props?.setInvitations}
          handleDialog={handleOpenDeleteDialog}
          isDelete={true}
          bouncedLoader={bouncedLoader}
          setBouncedLoader={setBouncedLoader}
        />
      }
    </>
  }

  return (<>
    {httpErrorMsg?.length > 0 &&
      <WeinvSnackbar horizontal='right' vertical='bottom' severity='error' msg={httpErrorMsg} handleClose={setHttpErrorMsg} />
    }

    {successMsg?.length > 0 &&
      <WeinvSnackbar horizontal='right' vertical='bottom' severity='success' msg={successMsg} handleClose={setSuccessMsg} />
    }

    {bouncedLoader && <BouncedLoader />}

    <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
      <div className="bg-white p-4 shadow-lg rounded-lg">

        <div style={{ display: "inline-block" }}>
          <h2 className="mr-5 text-lg font-semibold truncate">Liste des invités</h2>
        </div>
        <div style={{ display: "inline-block", float: "right" }} >
          <Button variant="outlined" startIcon={<FileUploadIcon />} onClick={() => handleDownload()}>Telecharger</Button>
        </div>

        <div className="guest-list _mg-b-lg _pd-t-sm">
          {/* <h3 className="tabs-container">Liste des invités</h3> */}
          <ul className="tabs">
            <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.ATTENDING.name)} className="selected">{InvitationStatus.ATTENDING.description} ({totalAttending})</li>
            <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.NOT_ATTENDING.name)}>{InvitationStatus.NOT_ATTENDING.description} ({totalNotAttending})</li>
            <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.MAYBE.name)}>{InvitationStatus.MAYBE.description} ({totalMaybe})</li>
            <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.NOT_REPLIED.name)} >{InvitationStatus.NOT_REPLIED.description} ({totalNotReplied})</li>
          </ul>
          <div className="tabs-wrap">
            <div className="tabs-holder">
              <select defaultValue={"ATTENDIND"} className="tabs select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                <option value="ATTENDIND">Attending (0)</option>
                <option value="NOT_ATTENDIND">Not Attending (0)</option>
                <option value="MAYBE">Maybe (0)</option>
                <option value="NOT_REPLIED">Not replied (0)</option>
              </select>
              <span onClick={(e) => selectEventsByStatus(e)} className="select2 select2-container select2-container--simple select2-container--below" dir="ltr" >
                <span className="selection">
                  <span className="select2-selection select2-selection--single" role="combobox" aria-haspopup="true" tabindex="0" aria-labelledby="select2-mvba-container">
                    <span className="select2-selection__rendered" id="select2-mvba-container" >{getcurrentStatus()} ({getCurrentTotalElements()})</span>
                    <span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          {props?.invitations?.length === 0 ? <>
            <div id="empty-msg" className="list empty wrap -size-sm display-empty-msg">{displayEmptyMsg()}</div>
          </> :
            <>
              <ul id="list-wrap-size-sm" className="list wrap -size-sm" data-status="1" style={{ display: "flex" }}>
                {props?.invitations.map((invitation, index) => (
                  <li>
                    {buildGuestDetailsHtmlElement(invitation, index)}
                  </li>
                ))}
              </ul>
            </>
          }

          <div id="select2-container--open" onClick={(e) => selectEventsByStatus(e)} className="noView">
            <span className="select2-container select2-container--simple" >
              <span className="select2-dropdown select2-dropdown--below" dir="ltr" style={{ width: "322px" }}>
                <span id="select2-results" className="select2-results">
                  <ul className="select2-results__options" role="tree" id="select2-mvba-results">
                    <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.ATTENDING.name)} className="select2-results__option select2-results__option--highlighted" id="select2-mvba-result-5wgf-1" role="treeitem" aria-selected="true">{InvitationStatus.ATTENDING.description} ({totalAttending})</li>
                    <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.NOT_ATTENDING.name)} className="select2-results__option" id="select2-mvba-result-79pb-2" role="treeitem" aria-selected="false">{InvitationStatus.NOT_ATTENDING.description} ({totalNotAttending})</li>
                    <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.MAYBE.name)} className="select2-results__option" id="select2-mvba-result-jl81-3" role="treeitem" aria-selected="false">{InvitationStatus.MAYBE.description} ({totalMaybe})</li>
                    <li onClick={(e) => getInvitationsByStatus(e, InvitationStatus.NOT_REPLIED.name)} className="select2-results__option" id="select2-mvba-result-w3fm-0" role="treeitem" aria-selected="false">{InvitationStatus.NOT_REPLIED.description} ({totalNotReplied})</li>
                  </ul>
                </span>
              </span>
            </span>
          </div>

        </div>



      </div>
      <div className="center">
        <Pagination style={{ fontWeight: 600 }} count={invitationstotalPages} page={page} onChange={getInvitationsPaginated} />
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props?.openLoaderGuestTable}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className="vertical-center" style={{ backgroundColor: "#fff", justifyContent: "center", display: "flex" }}>
          {props?.openLoaderGuestTable ? <img src={loadingGif} width={90} style={{ position: "fixed", zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 300 }} /> : null}
        </div>
      </div>
    </div>

  </>);
}


export default React.forwardRef(InvitationsTable);