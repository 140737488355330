import React, { useState, useEffect } from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Properties from '../../configs/Properties'


export default function WeddingsSection() {

  const [weddings, setEvents] = useState([]);
  // fake data: only for view purpose
  const fakeData = [
    { husbandName: 'Mike', wifeName: 'Prisca', date: '05 Janvier 2023', receptionVenue: '2816 Cinnamon Lane, San Antonio, Texas' },
    { husbandName: 'Christian', wifeName: 'Merveille', date: '22 Mars 2023', receptionVenue: '92 Faradje, kasa-vubu, Kinshasa' },
    { husbandName: 'Leonardo', wifeName: 'Francesca', date: '10 Avril 2023', receptionVenue: '95 Mercadante, 10154, Torino' }
  ];

  //? AXIOS GET ALL
  useEffect(() => {
    // axios.get(Properties.WEINV_BE_API_BASE + `${Properties.WEINV_EVENTS_PATH_BASE}all`,
    //   { headers: { "content-type": "application/json", "X-tx-id": uuidv4() } },
    //   { params: { offset: 0, limit: 3, sortingKeys: "data:DESC" } }
    // )
    //   .then((response) => {
    //     if (response.data.length === 3) setEvents(response.data);
    //     else setEvents(fakeData);
    //   })
    //   .catch((err) => {
    //     setEvents(fakeData);
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!weddings]);

  return (
    <section
      id="section-5feb3ffb74839"
      className="s123-module s123-module-events layout-4 section-5feb3ffb74839"
      data-module-id="5feb3ffb74839"
      data-module-type-num="2"
    >
      <div
        data-aos="fade-up"
        className="container-fluid page_header_style noBackground aos-init aos-animate"
      >
        <div className="row">
          <div className="container moduleTitleContainer">
            <div className="row modulesTitle text-center">
              <div className="col-xs-12">
                <div className="page-header-wrap">
                  <h2
                    id="section-5feb3ffb74839-title"
                    className="s123-page-header"
                  >
                    Événements
                  </h2>
                  <hr className="small" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container aos-init aos-animate" data-aos="fade-up">
        {/* Closest Event */}
        {/* Event */}
        <div className="row product-container preview-highlighter" data-unique-id="5feb3ffc0c046">
          {/* <div className="col-xs-12 col-sm-6 product-details">
            <div className="box box-primary s123-box-border" style={{ marginBottom: '5px' }}>
              <div className="close-msg box-text-primary">
                <span>Mariage</span>
              </div>
            </div>
            <div className="box box-primary s123-box-border">
              <div className="close-msg box-text-primary">
                <span>Anniversaire</span>
              </div>
            </div>
            <hr /> */}
          {/* Description */}
          {/*<h4>Nos valeurs</h4>
            <p className="breakable" style={{ fontWeight: 500 }}>
              <p style={{ marginBottom: '13px' }}>
                <strong>Qualité: </strong>Nous avons pour raison d’être la satisfaction de nos clients et nous nous assurons de toujours leur offrir un travail de la plus haute qualité.
              </p>
              <p>
                <strong>Innovation: </strong>En outre, nous encourageons une culture d’innovation afin d’assurer le développement continu de nos services répondant aux besoins de nos clients.
              </p>
            </p>
            <hr />
          </div> */}
          <div className="col-xs-12 col-sm-6 product-details">
            <div className="box box-primary s123-box-border" style={{ marginBottom: '5px' }}>
              <div className="close-msg box-text-primary">
                <span>Mariage</span>
              </div>
            </div>
            <div className="product-images">
              {/* Main Image */}
              <div className="main-image main-image-specs">
                <div className="img-gathering-wedding" role="img" aria-label="The Gathering"></div>
              </div>
            </div>
          </div>
          {/* Image */}
          <div className="col-xs-12 col-sm-6 product-details">
            <div className="box box-primary s123-box-border" style={{ marginBottom: '5px' }}>
              <div className="close-msg box-text-primary">
                <span>Anniversaire</span>
              </div>
            </div>
            <div className="product-images">
              {/* Main Image */}
              <div className="main-image main-image-specs">
                <div className="img-gathering-birthday" role="img" aria-label="The Gathering"></div>
              </div>
            </div>
          </div>
        </div>
        {/* All Events */}
        {/*<div className="row">
          <div className="events-container col-sm-12">
            <h3>Mariages récents</h3>
            <div className="events box-primary" style={{ fontWeight: 500 }}>
              <table className="modules-items-table table table-bordered box-text-primary">
                <thead>
                  <tr>
                    <th width="20%">Époux</th>
                    <th width="20%">Épouse</th>
                    <th width="20%">Date</th> */}
        {/* <th width="15%" className="hidden-xs">
                      Day
                    </th> */}
        {/*  <th width="35%">Lieu</th>
                  </tr>
                </thead>
                <tbody>
                  {weddings.map((w, index) => {
                    return (
                      <tr data-id="2158161389">
                        <td>
                          <div className="blu-color"> {weddings[index].husbandName}</div>
                        </td>
                        <td>
                          <div className="blu-color"> {weddings[index].wifeName}</div>
                        </td>
                        <td>{weddings[index].date}</td>  {/* 30 Nov 2021 */}
        {/* <td className="hidden-xs">Tuesday</td> */}
        {/*    <td>{weddings[index].receptionVenue}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}