import React from "react";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import Properties from '../configs/Properties'
import { translate, logout } from '../configs/Services'
import { useNavigate } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DialogGuestManagement from "./DialogGuestManagement";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import BouncedLoader from "./BouncedLoader";
import WeinvSnackbar from './WeinvSnackbar';
import DefaultData from "../configs/DefaultData";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import loadingGif from '../assets/loading.gif';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


function WeddingsTable(props, ref) {

  const maxNumberOfWeddings = 5;
  const navigate = useNavigate();
  const [page, setPage] = React.useState(1);
  const [invitationstotalPages, setInvitationstotalPages] = React.useState();
  const [bouncedLoader, setBouncedLoader] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState([false]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState([false]);
  const [httpErrorMsg, setHttpErrorMsg] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");


  React.useEffect(() => {
    getWeddings(null, null, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  React.useImperativeHandle(ref, () => ({
    preCallGetData,
  }));

  const preCallGetData = (e, searchKeyword, value) => {
    getWeddings(e, searchKeyword, value);
  }

  const getWeddingsPageable = (e, value) => {
    getWeddings(e, null, value);
  }

  const getWeddings = async (e, searchKeyword, value) => {
    if (!props?.openLoaderParent) {
      props?.setOpenLoaderGuestTable(true);
    }

    await axios.get(Properties.WEINV_BE_API_BASE + `${Properties.WEINV_EVENTS_PATH_BASE}all`,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        },
        params: {
          offset: (value - 1),
          limit: maxNumberOfWeddings
        }
      })
      .then((response) => {
        const size = response.data?.length;
        if (size > 0) {
          props?.setEvents(response.data);
          setInvitationstotalPages(response.data?.totalPages);
          setPage(value);
          setOpenEditDialog(Array(size).fill(false));
          setOpenDeleteDialog(Array(size).fill(false));
        }

        props?.setOpenLoaderGuestTable(false);
      })
      .catch((err) => {
        if ((err?.response.status === 401) || (err?.response.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const passwordRecovery = async (wedding, index) => {
    const data = {
      responsibleUUID: wedding?.responsibleUUID,
      date: new Date(wedding?.date),
      price: wedding?.price,
      responsibleNewPassword: wedding?.responsibleNewPassword
    }

    await axios.patch(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + wedding.uuid, data,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      }
    )
      .then(async (response) => {
        setSuccessMsg('Données modifiées avec succès');
        getWeddings(null, null, page);   // for update
      })
      .catch(async (err) => {
        if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
          setHttpErrorMsg('Session expirée');
          logout(); navigate('/signin');
        } else {
          setHttpErrorMsg(translate(err.response?.data.keyError));
        }
      });
    handleOpenEditDialog(index, false);
  }

  const handleOpenEditDialog = (index, value) => {
    openEditDialog[index] = value;
    setOpenEditDialog([...openEditDialog.slice(0, index), value, ...openEditDialog.slice(index + 1)])
  };

  const handleOpenDeleteDialog = (index, value) => {
    openDeleteDialog[index] = value;
    setOpenDeleteDialog([...openDeleteDialog.slice(0, index), value, ...openDeleteDialog.slice(index + 1)])
  };

  const deleteWedding = async (wedding, index) => {
    await axios.delete(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + wedding?.uuid,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        },
        params: { uuidEvent: wedding?.uuidEvent }
      }
    )
      .then(response => {
        setBouncedLoader(false);
        setSuccessMsg('Événement supprimé avec succès');
        getWeddings(null, null, page);   // for update
      })
      .catch((err) => {
        if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
          setHttpErrorMsg('Session expirée');
          logout(); navigate('/signin');
        } else {
          setHttpErrorMsg(translate(err.response?.data.keyError));
        }
        setBouncedLoader(false);
      });

    handleOpenDeleteDialog(index, false);
  }

  const getWeddingStatus = (responsible) => {
    if (!responsible?.expired) {
      return (<>{
        <div className="flex text-green-500" style={{ width: "80px", height: "25px"/*, margin: "0 auto"*/ }}>
          <CheckCircleOutlineOutlinedIcon sx={{ width: 18, marginTop: 0.4, marginRight: 0.7, stroke: "#ffffff", strokeWidth: 1 }} />
          <p>valide</p>
        </div>
      }</>);
    }
    else {
      return (<>{
        <div className="flex text-red-500" style={{ width: "80px", height: "25px"/*, margin: "0 auto"*/ }}>
          <CancelOutlinedIcon sx={{ width: 18, marginTop: 0.4, marginRight: 0.7, stroke: "#ffffff", strokeWidth: 1 }} />
          <p>expiré</p>
        </div>
      }</>);
    }
  }

  return (<>
    {httpErrorMsg?.length > 0 &&
      <WeinvSnackbar horizontal='right' vertical='bottom' severity='error' msg={httpErrorMsg} handleClose={setHttpErrorMsg} />
    }

    {successMsg?.length > 0 &&
      <WeinvSnackbar horizontal='right' vertical='bottom' severity='success' msg={successMsg} handleClose={setSuccessMsg} />
    }

    {bouncedLoader && <BouncedLoader />}

    <div className="grid gap-2 grid-cols-1 lg:grid-cols-1">
      <div className="bg-white p-4 shadow-lg rounded-lg">

        <div style={{ display: "inline-block" }}>
          <h2 className="mr-5 text-lg font-semibold truncate">Événement</h2>
        </div>

        <div className="mt-4">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white"></div>

                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell style={{ /*textAlign: "center", */backgroundColor: "#383c44" }}>Organisateur</StyledTableCell>
                        <StyledTableCell style={{ /*textAlign: "center", */backgroundColor: "#383c44" }}>Type</StyledTableCell>
                        <StyledTableCell style={{ /*textAlign: "center", */backgroundColor: "#383c44" }}>Prix</StyledTableCell>
                        <StyledTableCell style={{ /*textAlign: "center", */backgroundColor: "#383c44" }}>Statut</StyledTableCell>
                        <StyledTableCell style={{ /*textAlign: "center", */backgroundColor: "#383c44" }}>Action</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props?.weddings.map((wedding, index) => (
                        <StyledTableRow style={{ cursor: "pointer" }} key={index}>
                          <StyledTableCell component="th" scope="row" onClick={() => handleOpenEditDialog(index, true)}> {wedding?.responsibleUsername}</StyledTableCell>
                          <StyledTableCell component="th" scope="row" onClick={() => handleOpenEditDialog(index, true)}>{wedding?.type}</StyledTableCell>
                          <StyledTableCell /*style={{ textAlign: "center" }}*/ onClick={() => handleOpenEditDialog(index, true)}>{wedding?.price} $</StyledTableCell>
                          <StyledTableCell onClick={() => handleOpenEditDialog(index, true)}>{getWeddingStatus(wedding)}</StyledTableCell>

                          <StyledTableCell >
                            <div className="flex space-x-4" style={{ width: "100px", height: "50px"/*, margin: "0 auto"*/ }}>
                              <a href="#" className="text-red-500 hover:text-red-600 a-delete" onClick={() => handleOpenDeleteDialog(index, true)}>
                                <DeleteOutlineOutlinedIcon />
                                <p>Supprimer</p>
                              </a>
                              {!openDeleteDialog[index] ? null :
                                <DialogGuestManagement
                                  id={index}
                                  title="Supprimer l'événement"
                                  content="Êtes-vous sûr de vouloir supprimer cet événement?"
                                  actionMethod={deleteWedding}
                                  inputs={props?.weddings}
                                  setInputs={props?.setEvents}
                                  handleDialog={handleOpenDeleteDialog}
                                  isDelete={true}
                                  bouncedLoader={bouncedLoader}
                                  setBouncedLoader={setBouncedLoader}
                                />
                              }
                              {!openEditDialog[index] ? null :
                                <DialogGuestManagement
                                  id={index}
                                  title="Modifier l'événement"
                                  actionMethod={passwordRecovery}
                                  inputs={props?.weddings}
                                  setInputs={props?.setEvents}
                                  handleDialog={handleOpenEditDialog}
                                  isEditResponsible={true}
                                  bouncedLoader={bouncedLoader}
                                  setBouncedLoader={setBouncedLoader}
                                />
                              }
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="center">
        <Pagination style={{ fontWeight: 600 }} count={invitationstotalPages} page={page} onChange={getWeddingsPageable} />
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={props?.openLoaderGuestTable}
        >
          <CircularProgress color="inherit" />
        </Backdrop> */}
        <div className="vertical-center" style={{ backgroundColor: "#fff", justifyContent: "center", display: "flex" }}>
          {props?.openLoaderGuestTable ? <img src={loadingGif} width={90} style={{ position: "fixed", zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 300 }} /> : null}
        </div>
      </div>
    </div>
  </>);
}


export default React.forwardRef(WeddingsTable);