import * as React from 'react';
import Avatar from '@mui/material/Avatar';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const capitalizeFirst = str => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

const getPropsValue = (value) => {
  return value? value : "100px";
}

export default function ProfileAvatar(props) {
  const firstName = capitalizeFirst(props?.name?.split(" ")[0]);
  const lastName = capitalizeFirst(props?.name?.split(" ")[1]);
  return (
      <Avatar style={{width: getPropsValue(props?.width), height: getPropsValue(props?.height), fontSize: "1rem", fontFamily: "inherit"}} {...stringAvatar(firstName + " " + lastName)} />
  );
}