
import React, { useEffect, useState } from "react";
import Footer from './components/footer/Footer';
import FooterBar from './components/footer/FooterBar';
import HomeSection from './components/sections/HomeSection';
import ContactSection from './components/sections/ContactSection';
import AboutSection from './components/sections/AboutSection';
import WeddingsSection from './components/sections/WeddingsSection';
import PopupMenu from './components/PopupMenu';
import './stylesheets/minimize_main.css';
import './stylesheets/websiteCSS.css';
import './stylesheets/minimize-bottom.css';
import SearchAppBar from './components/SearchAppBar';
import axios from "axios";
import Properties from './configs/Properties';
import { v4 as uuidv4 } from 'uuid';
import { logout } from './configs/Services';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default function App() {

  
  const [displayValue, setDisplayValue] = useState();
  const [currentMenuActive, setCurrentMenuActive] = useState();
  const [userLogged, setUserLogged] = useState();

  // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcZ86c8wsR3mDkQNh18zUSafXK1tajsMY",
  authDomain: "weinv-10.firebaseapp.com",
  projectId: "weinv-10",
  storageBucket: "weinv-10.appspot.com",
  messagingSenderId: "909215783466",
  appId: "1:909215783466:web:d2f73bcf58da09111cc153",
  measurementId: "G-6SQ7K92H3W"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 
  useEffect(() => {
    if (!currentMenuActive) setCurrentMenuActive('home-selector');
    if (!displayValue) setDisplayValue('none');

    getUser();
    window.scrollTo(0, 0);
    window.addEventListener('scroll', handleScroll);

    // const interval = setInterval(() => {
    //   console.log('Logs every minute');
    //   axios.post(Properties.WEINV_BE_API_BASE + `/contact-us`, null, {headers: { "content-type": "application/json" }})
    // }, 60000);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      // clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleScroll = (e) => {
    if (window.scrollY > 200) setDisplayValue('block');
    else setDisplayValue('none');

    var scrollPos = 140;
    Array.from(document.querySelectorAll('.moduleMenu')).forEach(async currLink => {
      var refId = currLink.getAttribute("href");
      var refElement = Array.from(document.querySelectorAll(refId))[0].getBoundingClientRect();
      if (refElement.top <= scrollPos && refElement.top + refElement.height > scrollPos) {
        await Array.from(document.querySelectorAll('.moduleMenu')).forEach(element => element.classList.remove('active'));
        currLink.classList.add("active");
      }
    });
  };

  const getUser = async () => {
    const uuidUser = sessionStorage.getItem('uuidUser');

    // session expired/logout
    if (uuidUser === null) {
      logout();;
    }
    await axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + uuidUser,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => setUserLogged(response.data))
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout();;
        }
      });
  }

  const closePopupWin = (action) => {
    if (action) document.getElementById('popupFloatDivMenu')?.classList.remove('open');
    else document.getElementById('popupFloatDivMenu')?.classList.add('open');

    window.scrollTo(0, 0);
  }

  return (
    <html
      dir="ltr"
      lang="en"
      className="home_page s_layout1 isFreePackage s123-ads-banner-active page-loaded"
    >
      <head>
        <title>Weinv</title>

        {/* Status Bar */}
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        {/* Mobile Browser Address Bar Color */}
        <meta name="theme-color" content="#383c44" />
        {/* Regular Meta Info */}
        <title className="s123-js-pjax">WEINV - It's The Great Wedding</title>
        <meta
          name="description"
          content="Weinv - It's The Great Wedding"
          className="s123-js-pjax"
        />
        <meta name="keywords" content="" className="s123-js-pjax" />
        <link
          rel="canonical"
          href="https://64102f1bbbf68.site123.me/"
          className="s123-js-pjax"
        />
        {/* Facebook Meta Info */}
        <meta
          property="og:url"
          content="https://64102f1bbbf68.site123.me/"
          className="s123-js-pjax"
        />
        <meta
          property="og:image"
          content="https://static1.s123-cdn-static-a.com/ready_uploads/media/10830392/800_63e95e286d6f6.jpg"
          className="s123-js-pjax"
        />
        <meta
          property="og:description"
          content="Weinv - It's The Great Wedding"
          className="s123-js-pjax"
        />
        <meta
          property="og:title"
          content="Weinv - It's The Great Wedding"
          className="s123-js-pjax"
        />
        <meta
          property="og:site_name"
          content="Weinv"
          className="s123-js-pjax"
        />
        <meta
          property="og:see_also"
          content="https://64102f1bbbf68.site123.me"
          className="s123-js-pjax"
        />
        {/* Google+ Meta Info */}
        <meta
          itemprop="name"
          content="Weinv - It's The Great Wedding"
          className="s123-js-pjax"
        />
        <meta
          itemprop="description"
          content="Weinv - It's The Great Wedding"
          className="s123-js-pjax"
        />
        <meta
          itemprop="image"
          content="https://static1.s123-cdn-static-a.com/ready_uploads/media/10830392/800_63e95e286d6f6.jpg"
          className="s123-js-pjax"
        />
        {/* Twitter Meta Info */}
        <meta name="twitter:card" content="summary" className="s123-js-pjax" />
        <meta
          name="twitter:url"
          content="https://64102f1bbbf68.site123.me/"
          className="s123-js-pjax"
        />
        <meta
          name="twitter:title"
          content="Weinv - It's The Great Wedding"
          className="s123-js-pjax"
        />
        <meta
          name="twitter:description"
          content="Weinv - It's The Great Wedding"
          className="s123-js-pjax"
        />
        <meta
          name="twitter:image"
          content="https://static1.s123-cdn-static-a.com/ready_uploads/media/10830392/800_63e95e286d6f6.jpg"
          className="s123-js-pjax"
        />
        <link
          rel="stylesheet"
          className="f-a-css"
          href="https://cdn-cms-s.f-static.net/files/font-awesome-4.7/css/font-awesome.min.css?v=y82802"
        />
        <script src="chrome-extension://mooikfkahbdckldjjndioackbalphokd/assets/prompt.js"></script>
      </head>
      <body
        id="page-top"
        data-aos-easing="ease"
        data-aos-duration="200"
        data-aos-delay="0"
        className="main-body-bg-color"
      >
        <div className="body">
          {/* <Navbar closePopupWin={closePopupWin} /> */}
          <SearchAppBar isHomePage={true} isUserLogged={sessionStorage.getItem('uuidUser') ? true : false} userLogged={userLogged}/>
          <div id="s123PjaxMainContainer" >
            <HomeSection />
            <div id="s123ModulesContainer" className="s123-modules-container">
              <AboutSection />
              <WeddingsSection />
              <ContactSection />
            </div>
            <Footer showMenu={true} />
          </div>
        </div>

        <div></div>

        <FooterBar /*isColorBlu={false} color="#03d388"*/ />

        <a
          id="gotoTop"
          className="gotoTop page-scroll"
          style={{ 'display': displayValue }}
          href="#page-top"
        >
          <i
            className="svg-m s123-icon-converter mask-icon-angle"
            data-icon-name="angle-up"
            data-ie11-classes=""
            alt="angle-up"
          >
            &nbsp;
          </i>
        </a>
        
        {/* Pjax Helper */}
        <div className="s123-front-last-element hidden"></div>
        <PopupMenu closePopupWin={closePopupWin} />
      </body>
    </html>
  );
}

/*tslint:disabled*/