
import React from "react";

export default function PopupMenu(props) {
  
  return (
    <div id="popupFloatDivMenu" className="popupWin container  is-mobile oneColor ">
      <div className="cover"></div>
      <div className="content container">
        <div className="page" style={{ overflow: 'auto' }}>
          <ul className="navPagesPopup fancy-scrollbar" style={{ height: '291px' }}>
            {/* <!-- Website Menu Pages -->  */}
            <li className="moduleMenu home-selector active" href="#top-section" >
              <a className="page-scroll homepageMenu s123-fast-page-load" href="#page-top" >
                <span className="txt-container">Home</span>
              </a>
            </li>
            <li className="moduleMenu about-selector" data-menu-module-id="5b0a4ba50e4e8" href="#section-5b0a4ba50e4e8" >
              <a className="page-scroll s123-fast-page-load" href="#section-5b0a4ba50e4e8" >
                <span className="txt-container">À propos</span>
              </a>
            </li>
            <li className="moduleMenu weddings-selector" data-menu-module-id="5feb3ffb74839" href="#section-5feb3ffb74839" >
              <a className="page-scroll s123-fast-page-load" href="#section-5feb3ffb74839" >
                <span className="txt-container">Événements</span>
              </a>
            </li>
            <li className="moduleMenu contact-selector" data-menu-module-id="5b0a4ba521825" href="#section-5b0a4ba521825" >
              <a className="page-scroll s123-fast-page-load" href="#section-5b0a4ba521825" >
                <span className="txt-container">Contact</span>
              </a>
            </li>
            {/* <!-- Website Footer Pages -->  */}
          </ul>
          <div className="navPagesPopupActionButtons">
            <div className="navPagesPopupActionButtons_part1"></div>
          </div>
        </div>
      </div>
      <div className="popupCloseButton left">
        <i
          data-icon-name="times"
          className="svg-m  s123-icon-converter mask-icon-times"
          alt="times" onClick={() => props.closePopupWin(true)}> &nbsp;
        </i>
      </div>
    </div>
  );
}