import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import FooterBar from "../components/footer/FooterBar";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import Properties from '../configs/Properties';
import DefaultData from '../configs/DefaultData';
import WeinvSnackbar from '../components/WeinvSnackbar';
import { translate, logout, getEventImage } from '../configs/Services';
import { useNavigate, useParams } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import RestoreIcon from '@mui/icons-material/Restore';
import { Button, Box, Stack, Input, Typography, Switch, Checkbox, FormControlLabel, IconButton, Container, Toolbar, AppBar, TextField, CircularProgress, LinearProgress } from '@mui/material'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import loadingGif from '../assets/loading.gif';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import WeinvDialog from "../components/WeinvDialog";


dayjs.extend(utc);

export default function BirthdaySettings() {

  const [uuidUserParam] = React.useState(useParams().uuid);
  const [weinvEvent, setWeinvEvent] = React.useState();
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState(false);
  const [checkSameAddress, setCheckSameAddress] = React.useState(false);
  const [httpErrorMsg, setHttpErrorMsg] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");
  const [activeLinearProgress, setActiveLinearProgress] = React.useState(false);
  const [activeCircularProgress, setActiveCircularProgress] = React.useState(false);
  const [disableCeremonyFields, setDisableCeremonyFields] = React.useState(false);
  const [userLogged, setUserLogged] = React.useState({});
  const [spousesImage, setSpousesImage] = React.useState(null);
  const [time, setTime] = React.useState('00:00');
  const [cropper, setCropper] = React.useState();
  const [cropData, setCropData] = React.useState("#");
  const [openImgCropModal, setOpenImgCropModal] = React.useState(false);

  React.useEffect(() => {
    getResponsible();
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      setSpousesImage(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const reset = () => {
    getEvent();
    getEventImage().then((image) => setSpousesImage(image));
  };

  const getEvent = async () => {
    const uuidEvent = sessionStorage.getItem('uuidEvent')
    await axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + uuidEvent,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then(async (response) => {
        if (response.data) {
          setCheckSameAddress(response.data?.sameAddress);
          setWeinvEvent({
            ...response.data,
            date: dayjs(new Date(response.data?.date)),
            deadlineConfirmInvitation: dayjs(new Date(response.data?.deadlineConfirmInvitation)),
            receptionStartime: dayjs.utc('2000-01-01T' + response.data?.receptionStartime),
            invitationText: response.data.invitationText ? response.data.invitationText : DefaultData.INVITATION_TEXT
          });
          if (response.data?.weddingCategory === 'TRADITIONAL') {
            setDisableCeremonyFields(true);
            setTime(dayjs.utc(new Date()));
          } else {
            setDisableCeremonyFields(false);
            setTime(dayjs.utc('2000-01-01T' + response.data?.time));
          }
        } else {
          initializeEvent();
          sessionStorage.setItem("uuidEvent", null);
        }
      })
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        } else if (err?.response?.status === 500) {
          setHttpErrorMsg(translate(""));
        } else setHttpErrorMsg(translate(err.response?.data.keyError));

      });
  }

  const getResponsible = async () => {
    await axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + uuidUserParam,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => setUserLogged(response.data))
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const upSertEvent = async (e) => {
    e.preventDefault();
    setActiveLinearProgress(true);

    const data = {
      husbandName: weinvEvent?.husbandName,
      wifeName: weinvEvent?.wifeName,
      date: new Date(weinvEvent?.date),
      deadlineConfirmInvitation: new Date(weinvEvent?.deadlineConfirmInvitation),
      address: weinvEvent?.address,
      time: time,
      receptionVenue: weinvEvent.receptionVenue,
      receptionStartime: weinvEvent.receptionStartime,
      maxInvitations: weinvEvent?.maxInvitations,
      maxTables: weinvEvent?.maxTables,
      invitationText: weinvEvent?.invitationText,
      giftDetails: weinvEvent?.giftDetails,
      responsibleUUID: userLogged?.uuid,
      spousesImage: spousesImage,
      type: "BIRTHDAY",
      sameAddress: checkSameAddress,
      eventName: weinvEvent?.eventName,
      eventOwnerFirstName: weinvEvent?.eventOwnerFirstName,
      eventOwnerLastName: weinvEvent?.eventOwnerLastName,
      dressCode: weinvEvent?.dressCode
    }

    const uuidEvent = sessionStorage.getItem('uuidEvent');
    if (!uuidEvent || uuidEvent === 'null') {
      await axios.post(Properties.WEINV_BE_API_BASE + `/events`, data,
        {
          headers: {
            "content-type": "application/json",
            "X-tx-id": uuidv4(),
            "Authorization": "Bearer " + sessionStorage.getItem('access-token')
          }
        }
      )
        .then(async (response) => handleCreateEventResponse(response))
        .catch(async (err) => {
          // only for showing linear progress
          await delay(3000);
          setActiveLinearProgress(false);

          if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
            setHttpErrorMsg('Session expirée');
            logout(); navigate('/signin');
          } else {
            setHttpErrorMsg(translate(err.response?.data.keyError));
          }
        });

    } else {
      await axios.patch(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + uuidEvent, data,
        {
          headers: {
            "content-type": "application/json",
            "X-tx-id": uuidv4(),
            "Authorization": "Bearer " + sessionStorage.getItem('access-token')
          }
        }
      )
        .then(async (response) => {
          setSuccessMsg('Données modifiées avec succès');
          // only for showing linear progress
          await delay(3000);
          setActiveLinearProgress(false);
          navigate(`/profiles/${userLogged?.uuid}/dashboard`);
        })
        .catch(async (err) => {
          // only for showing linear progress
          await delay(3000);
          setActiveLinearProgress(false);

          if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
            setHttpErrorMsg('Session expirée');
            logout(); navigate('/signin');
          } else {
            setHttpErrorMsg(translate(err.response?.data.keyError));
          }
        });
    }
  }

  const handleCreateEventResponse = async (response) => {
    setSuccessMsg('Données modifiées avec succès');
    // only for showing linear progress
    await delay(3000);
    setActiveLinearProgress(false);
    sessionStorage.setItem("uuidEvent", response.data);
    navigate(`/profiles/${userLogged?.uuid}/dashboard`);
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const initializeEvent = () => {
    const now = new Date();

    setWeinvEvent({
      date: dayjs(now),
      receptionStartime: dayjs.utc(now),
      deadlineConfirmInvitation: dayjs(now),
      giftDetails: DefaultData.GIFT_DETAILS,
      invitationText: DefaultData.INVITATION_TEXT,
      responsibleUUID: userLogged.uuid,
      spousesImage: null
    });
    setTime(dayjs.utc(now));
  }

  const handleFileUpload = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setCropData(reader.result);
      setOpenImgCropModal(true);
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <div>
      <div className="vertical-center" style={{ backgroundColor: "#fff", justifyContent: "center", display: "flex" }}>
        {activeCircularProgress ? <img src={loadingGif} width={90} style={{ position: "fixed", zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 300 }} /> : null}
      </div>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "black" }}
        open={activeCircularProgress}
      >
        <CircularProgress className="color-gray" />
      </Backdrop> */}

      <AppBar sx={{ position: 'relative', mb: 6, backgroundColor: "#383c44" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigate(`/profiles/${userLogged?.uuid}/dashboard`)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {weinvEvent?.uuid ? "Modifier l'invitation" : "Créer votre invitation"}
          </Typography>
          <Box className="flex-right">
            <Button sx={{ mr: 4 }} color="inherit" onClick={reset}>
              Réinitialiser
            </Button>
            <Button color="inherit" onClick={(e) => upSertEvent(e)}>
              Sauvegarder
            </Button>
          </Box>
        </Toolbar>

        {activeLinearProgress &&
          <Box sx={{ width: '100%', position: 'absolute' }}>
            <LinearProgress />
          </Box>
        }
      </AppBar>

      {httpErrorMsg?.length > 0 && <WeinvSnackbar severity='error' horizontal='right' vertical='bottom' msg={httpErrorMsg} handleClose={setHttpErrorMsg} />}
      {successMsg?.length > 0 && <WeinvSnackbar severity='success' horizontal='right' vertical='bottom' msg={successMsg} handleClose={setSuccessMsg} />}

      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box component="form" sx={{ "& .MuiTextField-root": { mt: 2 }, width: "100%" }} noValidate>
            <Box maxWidth="md" sx={{ mb: 1 }}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <label htmlFor="upload-image">
                  <Input sx={{ display: 'none' }} accept="image/*" id="upload-image" type="file" onChange={(e) => handleFileUpload(e)} />
                  <Box sx={{ display: "inline-block" }}>
                    <p className="label-free">Insérer votre photo</p>
                  </Box>
                  <Box sx={{ display: "inline-block" }} >
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </Box>

                  {openImgCropModal && <WeinvDialog
                    buttonConfirmationText={"Rogner l'image"}
                    buttonAnnulationtionText={"Annuler"}
                    content={<Cropper
                      style={{ height: 400, width: "100%" }}
                      zoomTo={0.0}
                      initialAspectRatio={1}
                      preview=".img-preview"
                      src={cropData}
                      viewMode={1}
                      minCropBoxHeight={10}
                      minCropBoxWidth={10}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                      onInitialized={(instance) => setCropper(instance)}
                      guides={true}
                    />}
                    openDialog={openImgCropModal}
                    setOpenDialog={setOpenImgCropModal}
                    onClickConfirm={getCropData} />
                  }
                </label>
                {!spousesImage ? <Skeleton animation="wave" variant="rectangular" width={70} height={70} /> : <>
                  <img src={spousesImage} alt="photo téléchargée" width={70} height={70} />
                  <Tooltip title="Restaurez la photo initiale" placement="right">
                    <RestoreIcon sx={{ cursor: "pointer" }} color="primary" onClick={() => getEventImage().then((image) => setSpousesImage(image))} />
                  </Tooltip></>
                }
              </Stack>
            </Box>

            {weinvEvent ? <>
              <Box sx={{ marginBottom: 5 }}>
                <TextField
                  autoComplete="urlVideo"
                  className="full-width"
                  sx={styles(weinvEvent?.urlVideo?.length === 0, '100%', '0')}
                  id="urlVideo"
                  label="Lien de la vidéo"
                  error={weinvEvent?.urlVideo?.length === 0}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={getValue(weinvEvent?.urlVideo)}
                  onChange={(e) => setWeinvEvent({ ...weinvEvent, urlVideo: e.target.value })}
                />
              </Box>

              <Box sx={{ marginBottom: 1, color: "#20282d", fontWeight: "bold" }}>DÉTAILS DE L'ÉVÈNEMENT</Box>


              <TextField
                required
                className="full-width"
                sx={styles(weinvEvent?.eventName?.length === 0, null, null)}
                margin="normal"
                fullWidth
                id="Nom de l'évènement"
                label="Nom de l'évènement"
                error={weinvEvent?.eventName?.length === 0}
                InputLabelProps={{ shrink: true }}
                value={getValue(weinvEvent?.eventName)}
                onChange={(e) => setWeinvEvent({ ...weinvEvent, eventName: e.target.value })}
              />
              <TextField
                required
                className="full-width"
                sx={styles(weinvEvent?.eventOwnerFirstName?.length === 0, '49%', '2%')}
                margin="normal"
                id="eventOwnerFirstName"
                label="Prénom"
                error={weinvEvent?.eventOwnerFirstName?.length === 0}
                InputLabelProps={{ shrink: true }}
                value={getValue(weinvEvent?.eventOwnerFirstName)}
                onChange={(e) => setWeinvEvent({ ...weinvEvent, eventOwnerFirstName: e.target.value })}
              />
              <TextField
                required
                className="full-width"
                sx={styles(weinvEvent?.eventOwnerLastName?.length === 0, '49%', '0')}
                id="eventOwnerLastName"
                label="Nom"
                error={weinvEvent?.eventOwnerLastName?.length === 0}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={getValue(weinvEvent?.eventOwnerLastName)}
                onChange={(e) => setWeinvEvent({ ...weinvEvent, eventOwnerLastName: e.target.value })}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                  required
                  className="full-width"
                  sx={styles(!weinvEvent?.date, '49%', '2%')}
                  label="Date de l'évènement"
                  minDate={dayjs(new Date())}
                  margin="normal"
                  format="DD/MM/YYYY"
                  error={!weinvEvent?.date}
                  value={weinvEvent?.date}
                  onChange={(newValue) => setWeinvEvent({ ...weinvEvent, date: newValue })}
                />
                <DatePicker
                  className="full-width mt-17"
                  sx={styles(!weinvEvent?.deadlineConfirmInvitation, '49%', '0')}
                  label="Date limite confirmation"
                  minDate={dayjs(new Date())}
                  margin="normal"
                  format="DD/MM/YYYY"
                  error={!weinvEvent?.deadlineConfirmInvitation}
                  value={weinvEvent?.deadlineConfirmInvitation}
                  onChange={(newValue) => setWeinvEvent({ ...weinvEvent, deadlineConfirmInvitation: newValue })}
                />
              </LocalizationProvider>
              <Box sx={{ mt: 1 }}>
                <TextField
                  required
                  autoComplete="responsible"
                  id="responsible"
                  label="Organisateur"
                  className="full-width mt-17"
                  sx={styles(false, '32%', '2%')}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  error={!userLogged?.firstName || !userLogged?.lastName}
                  value={userLogged?.firstName + ' ' + userLogged?.lastName}
                  disabled={true}
                />
                <TextField
                  autoComplete="maxInvitations"
                  id="maxInvitations"
                  label="Nombre d'invités"
                  type="number"
                  className="full-width"
                  sx={styles(false, '32%', '2%')}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={getValue(weinvEvent?.maxInvitations)}
                  onChange={(e) => setWeinvEvent({ ...weinvEvent, maxInvitations: e.target.value })}
                />
                <TextField
                  autoComplete="maxTables"
                  id="maxTables"
                  label="Nombre de tables"
                  type="number"
                  className="full-width"
                  sx={styles(false, '32%', '0')}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  value={getValue(weinvEvent?.maxTables)}
                  onChange={(e) => setWeinvEvent({ ...weinvEvent, maxTables: e.target.value })}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={<Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />}
                  label="Souhaitez-vous spécifier les types des cadeaux ?"
                />
              </Box>
              {!checked ? null :
                <Box sx={{ marginBottom: 2 }}>
                  <TextField
                    label="Types des cadeaux"
                    required
                    sx={styles(false, '100%', '0')}
                    minRows={3}
                    maxRows={4}
                    multiline
                    /*defaultValue="Default Value"*/
                    value={weinvEvent?.giftDetails}
                    onChange={(e) => setWeinvEvent({ ...weinvEvent, giftDetails: e.target.value })}
                  />
                </Box>
              }
              <Box sx={{ mt: 1, mb: 15 }}>
                <TextField
                    label="Lettre d'invitation"
                    required
                    sx={styles(false, '100%', '0')}
                    minRows={3}
                    maxRows={4}
                    multiline
                    /*defaultValue="Default Value"*/
                    value={weinvEvent?.invitationText}
                    onChange={(e) => setWeinvEvent({ ...weinvEvent, invitationText: e.target.value })}
                  />
              </Box>

              <Box sx={{ marginBottom: 1, color: "#20282d", fontWeight: "bold" }}>ADRESSES</Box>


              <Box sx={{ mb: 15 }}>
                <TextField
                  required
                  placeholder="Numero civique, avenue, comune, ville"
                  id="EventVenue"
                  label="Adresse de l'évènement"
                  className="full-width mt-17"
                  sx={styles(weinvEvent?.receptionVenue?.length === 0, '68%', '2%')}
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                  error={weinvEvent?.receptionVenue?.length === 0}
                  value={getValue(weinvEvent?.receptionVenue)}
                  onChange={(e) => setWeinvEvent({ ...weinvEvent, receptionVenue: e.target.value })}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    required
                    className="full-width"
                    sx={styles(!weinvEvent?.receptionStartime, '30%', '0')}
                    label="Début de l'évènement"
                    margin="normal"
                    ampm={false}
                    error={!weinvEvent?.receptionStartime}
                    value={weinvEvent?.receptionStartime}
                    onChange={(newValue) => setWeinvEvent({ ...weinvEvent, receptionStartime: newValue })}
                  />
                </LocalizationProvider>
              </Box>
            </> : <>
              <Skeleton animation="wave" height={90} width="100%" sx={{ marginBottom: 4 }} />
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block", marginRight: "2%" }} />
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block" }} />
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block", marginRight: "2%" }} />
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block" }} />
              <Skeleton animation="wave" height={90} width="32%" sx={{ display: "inline-block", marginRight: "2%" }} />
              <Skeleton animation="wave" height={90} width="32%" sx={{ marginRight: "2%", display: "inline-block" }} />
              <Skeleton animation="wave" height={90} width="32%" sx={{ display: "inline-block" }} />
              <Skeleton animation="wave" height={90} width="100%" />
              <Skeleton animation="wave" height={90} width="68%" sx={{ display: "inline-block", marginRight: "2%" }} />
              <Skeleton animation="wave" height={90} width="30%" sx={{ display: "inline-block" }} />
              <Skeleton animation="wave" height={90} width="68%" sx={{ display: "inline-block", marginRight: "2%" }} />
              <Skeleton animation="wave" height={90} width="30%" sx={{ display: "inline-block" }} />
              <Skeleton sx={{ mt: 2 }} animation="wave" height={20} width="60%" />
              <Skeleton animation="wave" sx={{ mt: 2, mb: 15 }} height={150} width="100%" variant="rectangular" />
            </>
            }
          </Box>
        </Box>
        <FooterBar /*isColorBlu={true} color="#008ffb"*/ />
      </Container>
    </div >
  );
}



const styles = (isError, width, marginRight) => {
  if (isError) {
    return {
      '& label.Mui-error': { color: '#d32f2f !important' },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      width: width,
      marginRight: marginRight
    }
  } else {
    if (width === null && marginRight === null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' }
      }
    } else if (width !== null && marginRight !== null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        width: width,
        marginRight: marginRight
      }
    }
  }
};

const getValue = (value) => {
  return !value ? value : String(value);
}
