import React, { useState } from "react";
import axios from "axios";
import Properties from '../../configs/Properties'


export default function ContactSection() {

  const [name, setName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [from, setFrom] = useState();
  const [message, setMessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();
      const data = {
          name: name,
          phoneNumber: phoneNumber,
          from: from,
          message: message
      };

    axios.post(Properties.WEINV_BE_API_BASE + `/contact-us`, data, {headers: { "content-type": "application/json" }})
    .then((response) => {
      alert("Message envoyé avec succès");
      setName("");
      setPhoneNumber("");
      setFrom("");
      setMessage("");
    })
    .catch((err) => alert("Impossible d'envoyer le message"));
  }

  return (
    <section
      id="section-5b0a4ba521825"
      className="s123-module s123-module-contact layout-1 bg-primary section-5b0a4ba521825"
      data-module-id="5b0a4ba521825"
      data-module-type-num="7"
    >
      <div
        data-aos="fade-up"
        className="container-fluid page_header_style noBackground aos-init aos-animate"
      >
        <div className="row">
          <div className="container moduleTitleContainer">
            <div className="row modulesTitle text-center">
              <div className="col-xs-12">
                <div className="page-header-wrap">
                  <h2
                    id="section-5b0a4ba521825-title"
                    className="s123-page-header"
                  >
                    Contact
                  </h2>
                  <hr className="small" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="row text-center">
          <div
            className="col-md-5 preview-highlighter preview-highlighter-center"
          >
            {/* <ul className="list-unstyled contact-as-details-container">
              <li>Italy</li>
            </ul> */}
            <ul className="list-unstyled contact-as-details-container">
              {/* <li>
                          <a href="tel:+393511408154">
                            <i
                              className="svg-m s123-icon-converter fa-fw mask-icon-phone"
                              data-icon-name="phone"
                              data-ie11-classes=" fa-fw"
                              alt="phone"
                            >
                              &nbsp;
                            </i>
                             <span dir="ltr">+393511408154</span> 
                          </a>
                        </li> */}
              <li>
                <a href="mailto:threadnicy@gmail.com" style={{ fontWeight: 500 }}>
                  <i
                    className="svg-m s123-icon-converter fa-fw mask-icon-envelope"
                    data-icon-name="envelope-o"
                    data-ie11-classes=" fa-fw"
                    alt="envelope-o"
                  >
                    &nbsp;
                  </i>
                  threadnicy@gmail.com
                </a>
              </li>
            </ul>
          </div>
          <form
            className="breakable contactUsForm"
            data-date-format="d/m/Y"
            data-click-action="thankYouMessage"
            novalidate="novalidate"
            onSubmit={sendEmail}
          >
            <div className="preview-highlighter col-md-6 col-md-offset-1">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="contact_name"
                      placeholder="Ton Nom"
                      className="form-control"
                      required=""
                      data-msg-required="This field is required."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="contact_phone"
                      placeholder="Ton numéro de téléphone"
                      className="form-control"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="contact_email"
                  placeholder="Ton address email"
                  className="form-control s123-force-ltr"
                  required=""
                  data-msg-required="This field is required."
                  data-rule-email="true"
                  data-msg-email="Please enter a valid email."
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control f-c-text-area-specs"
                  name="contact_message"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button
                type="submit"
                class={"btn-block gi-btn gi-btn-primary"}
                aria-label="Contact Us"
                disabled={!(name && from && message)}
              >
                Contactez-nous
              </button>
            </div>
            <input type="hidden" name="w" value="" />
            <input type="hidden" name="websiteID" value="7669337" />
            <input
              type="hidden"
              name="moduleID"
              value="5b0a4ba521825"
            />
            <input type="hidden" name="layout" value="1" />
            <input type="hidden" name="recaptchaToken" value="" />
          </form>
        </div>
      </div>
    </section>
  );
}