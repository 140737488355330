import React from "react";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './dashboard.css';
import Properties from '../../configs/Properties';
import { logout, Search, SearchIconWrapper, StyledInputBase } from '../../configs/Services';
import WeinvProfileIcon from '../../components/icon/WeinvProfileIcon';
import WeinvCreationIcon from '../../components/icon/WeinvCreationIcon';
import WeinvUpdateIcon from '../../components/icon/WeinvUpdateIcon';
import WeinvMessageIcon from '../../components/icon/WeinvMessageIcon';
import InvitationsTable from "../../components/InvitationsTable";
import DialogInviteGuest from "../../components/DialogInviteGuest";
import SearchAppBar from "../../components/SearchAppBar";
import SearchIcon from "@mui/icons-material/Search";
import '../../stylesheets/websiteCSS2.css';
import loadingGif from '../../assets/loading.gif';


export default function ResponsibleDashboard() {
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `React.useRef()` to get one
  const invitationsTableChildRef = React.useRef();

  const navigate = useNavigate();
  const [invitations, setInvitations] = React.useState([]);
  const [wedding, setEvent] = React.useState();
  const [userLogged, setUserLogged] = React.useState();
  const [openInviteGuestDialog, setOpenInviteGuestDialog] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(true);
  const [openLoaderGuestTable, setOpenLoaderGuestTable] = React.useState(false);

  React.useEffect(() => {
    retrieveData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openInviteGuestDialog]);

  const retrieveData = async () => {
    const uuidUser = sessionStorage.getItem('uuidUser');
    const uuidEvent = sessionStorage.getItem('uuidEvent');

    // session expired/logout
    if (uuidUser === null || uuidEvent === null) {
      logout(); navigate('/signin');
    }

    if (!userLogged || !wedding) {
      await Promise.all([getUser(uuidUser), getWedding(uuidEvent)]).then(responses => {
        setUserLogged(responses[0]?.data);
        setEvent(responses[1]?.data);
        setOpenLoader(false);

        const notif = sessionStorage.getItem('badge-content-n-notifications');
        if (!notif) {
          sessionStorage.setItem('badge-content-n-notifications', 1);
        }
        if (responses[1]?.data) sessionStorage.setItem("badge-content-n-messages", 0);
        else sessionStorage.setItem('badge-content-n-messages', 1);
      });
    }
  }

  const getUser = (uuid) => {
    return axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + uuid,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => { return response })
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const getWedding = (uuid) => {
    return axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + uuid,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => { return response })
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const getWeddingCreationOrConfigurationIcon = () => {
    return !wedding ? <>
      <WeinvCreationIcon />
      <p className="m-c">Créer votre invitation</p>
    </> : <>
      <WeinvUpdateIcon />
      <p className="m-c">Modifier l'invitation</p>
    </>
  }

  const searchInvitation = (e) => {
    e.preventDefault();
    invitationsTableChildRef?.current?.preCallGetData(e.target.value);
  }


  return (<>
    <div className="vertical-center" style={{ backgroundColor: "#fff", justifyContent: "center", display: "flex" }}>
      {openLoader ? <img src={loadingGif} width={90} style={{ position: "fixed", zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 300 }} /> : null}
    </div>
    {/* <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "white" }}
      open={openLoader}
    >  
    <img src={loadingGif} alt="loading..." />
       <CircularProgress className="color-gray" /> 
    </Backdrop> */}

    {!openLoader && <>
      <SearchAppBar isDashboard={true} invitationsTableChildRef={invitationsTableChildRef} wedding={wedding} isUserLogged={true} userLogged={userLogged} />
      <div className="grid mb-4 pb-10 px-8">
        <div className="grid grid-cols-12 gap-3">
          <div className="grid grid-cols-12 col-span-12 gap-3 xxl:col-span-9">
            <div className="col-span-12 mt-8">
              <div className="flex items-center h-10 intro-y" style={{ display: "inline-block" }}>
                <div style={{ float: "left" }}>
                  <h2 className="mr-5 text-lg font-semibold truncate">Dashboard</h2>
                </div>
                <div className="search-details css-only-small-device" style={{ float: "right" }} >
                  <Search >
                    <SearchIconWrapper>
                      <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => searchInvitation(e)}
                    />
                  </Search>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-3 mt-5">
                {userLogged?.type === 'WEDDING' ? <>
                  <a href="#" onClick={() => navigate(`/profiles/${userLogged?.uuid}/wedding-settings`)} className="card shadow-xl rounded-lg col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
                    {getWeddingCreationOrConfigurationIcon()}
                  </a>
                </> : <>
                  <a href="#" onClick={() => navigate(`/profiles/${userLogged?.uuid}/settings`)} className="card shadow-xl rounded-lg col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
                    {getWeddingCreationOrConfigurationIcon()}
                  </a>
                </>}
                <a href="#" className="card shadow-xl rounded-lg col-span-12 sm:col-span-4 xl:col-span-4 intro-y" onClick={() => setOpenInviteGuestDialog(true)} >
                  <WeinvMessageIcon />
                  <p className="a-c">Envoyer une invitation</p>
                </a>
                {openInviteGuestDialog &&
                  <DialogInviteGuest
                    wedding={wedding}
                    userLogged={userLogged}
                    openInviteGuestDialog={openInviteGuestDialog}
                    setOpenInviteGuestDialog={setOpenInviteGuestDialog}
                    invitationsTableChildRef={invitationsTableChildRef}
                    title={'Envoyer une invation'}
                    btnAction={'Inviter'}
                  />
                }
                <a href="#" onClick={() => navigate(`/profiles/${userLogged?.uuid}/infos`)} className="card shadow-xl rounded-lg col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
                  <WeinvProfileIcon paddingTop={"10px"} />
                  <p className="a-c">Profile</p>
                </a>
              </div>
            </div>
            <div className="col-span-12 mt-5">

              <InvitationsTable
                ref={invitationsTableChildRef}
                wedding={wedding}
                userLogged={userLogged}
                invitations={invitations}
                setInvitations={setInvitations}
                openLoaderGuestTable={openLoaderGuestTable}
                setOpenLoaderGuestTable={setOpenLoaderGuestTable}
                openLoaderParent={openLoader}
              />
            </div>
          </div>
        </div>
      </div>
    </>}
  </>);
}
