
const Properties = {
    WEINV_UI_HOME: "https://weinv.it/",
    //WEINV_UI_HOME: "https://weinv.netlify.app/",
    //WEINV_BE_API_BASE: "http://localhost:8888/weinv", 
    //WEINV_BE_API_BASE: "https://weinv-services.onrender.com/weinv",
    //WEINV_BE_API_BASE: "https://weinv-service.cleverapps.io/weinv",
    //WEINV_BE_API_BASE: "https://weinv-ngrymaky.b4a.run/weinv",
    WEINV_BE_API_BASE: "https://convincing-krystle-threadn.koyeb.app/weinv",
    WEINV_EVENTS_PATH_BASE: "/events/",
    WEINV_USERS_PATH_BASE: "/users/",
 /*   WEINV_SHARE_TO_WHATSAPP_TYPE_WEDDING: 
    `Mr./Ms. <firstName> <lastName>, vous êtes cordialement invité(e) au Mariage de <husbandName> et <wifeName>.\n
    Cliquez sur le lien suivant pour voir les details et confirmer votre présence: <weinv_ui_home>
    Vos informations d'identification:
    identifiant: *<username>*
    mot de passe: *<password>* \n`,*/
    WEINV_SHARE_TO_WHATSAPP_TYPE_WEDDING: 
    `Mr./Ms. <firstName> <lastName>, vous êtes cordialement invité(e) au Mariage <category> de <husbandName> et <wifeName>.\n
    Cliquez sur le lien suivant pour voir les details et confirmer votre présence: <weinv_ui_home> \n`,
    WEINV_SHARE_TO_WHATSAPP_TYPE_WEDDING_FAMILY: 
    `<firstName> <lastName>, vous êtes cordialement invité au Mariage <category> de <husbandName> et <wifeName>.\n
    Cliquez sur le lien suivant pour voir les details et confirmer votre présence: <weinv_ui_home> \n`,
    /*Vos informations d'identification:
    identifiant: *<username>*
    mot de passe: *<password>* \n`,*/
    //*<giftDetails>*`,
    WEINV_SHARE_TO_WHATSAPP_TYPE_OTHERS: 
    `Mr./Ms. <firstName> <lastName>, vous êtes cordialement invité(e) à l'événement <eventName>.\n
    Cliquez sur le lien suivant pour voir les details et confirmer votre présence: <weinv_ui_home>
    Vos informations d'identification:
    identifiant: *<username>*
    mot de passe: *<password>* \n`,
    WEINV_SHARE_RESPONSIBLE_DATA_TO_WHATSAPP: 
    `<weinv_ui_home>\n
    Vos identifiants pour gérer votre événement:
    identifiant: *<username>*
    mot de passe: *<password>* \n`
}

export default Properties;