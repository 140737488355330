import React from "react";
import Button from '@mui/material/Button';
import { TextField } from "@mui/material";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import WeinvProfileIcon from '../components/icon/WeinvProfileIcon'
import MenuItem from '@mui/material/MenuItem';
import loadingGif from '../assets/loading.gif';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Visibility from '@mui/icons-material/Visibility';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FooterBar from "../components/footer/FooterBar";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import Properties from '../configs/Properties';
import LinearProgress from '@mui/material/LinearProgress';
import WeinvSnackbar from '../components/WeinvSnackbar';
import { translate, logout } from '../configs/Services';
import { useNavigate, useParams } from "react-router-dom";



const styles = (isError, width, marginRight) => {
  if (isError) {
    return {
      '& label.Mui-error': { color: '#d32f2f !important' },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#d32f2f !important', borderWidth: '1px !important' },
      width: width,
      marginRight: marginRight
    }
  } else {
    if (width === null && marginRight === null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' }
      }
    } else if (width !== null && marginRight !== null) {
      return {
        '& label.Mui-focused': { color: '#03d388 !important' },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#03d388 !important', borderWidth: '1px !important' },
        width: width,
        marginRight: marginRight
      }
    }
  }
};


export default function DialogUpdateProfile() {

  const [uuidParam, setUuidParam] = React.useState(useParams().uuid);
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = React.useState();
  const [confirmNewPassword, setConfirmNewPassword] = React.useState();
  const [currentPassword, setCurrentPassword] = React.useState();
  const [checked, setChecked] = React.useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [currentPasswordType, setCurrentPasswordType] = React.useState('password');
  const [confirmNewPasswordType, setConfirmNewPasswordType] = React.useState('password');
  const [newPasswordType, setNewPasswordType] = React.useState('password');
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [pwErrorMessage, setPwErrorMessage] = React.useState();
  const [currentPwErrorMessage, setCurrentPwErrorMessage] = React.useState();
  const [httpErrorMsg, setHttpErrorMsg] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");
  const [isCurrentPwError, setIsCurrentPwError] = React.useState(false);
  const [isPwError, setIsPwError] = React.useState(false);
  const [isConfirmPwError, setIsConfirmPwError] = React.useState(false);
  const [activeLinearProgress, setActiveLinearProgress] = React.useState(false);
  const [activeCircularProgress, setActiveCircularProgress] = React.useState(true);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);
  const [userLogged, setUserLogged] = React.useState();
  const [role, setRole] = React.useState(sessionStorage.getItem('roles'));


  React.useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = () => {
    getResponsible();
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setActiveCircularProgress(false);  // to remove
  };

  const getResponsible = async () => {
    //call();
    await axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + uuidParam,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => { setUserLogged(response.data); setActiveCircularProgress(false); })
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  const updateProfile = async (e) => {
    e.preventDefault();
    setActiveLinearProgress(true);

    const data = {
      firstName: userLogged?.firstName,
      lastName: userLogged?.lastName,
      email: userLogged?.email,
      phoneNumber: userLogged?.phoneNumber,
      language: userLogged?.language,
      currentPassword: currentPassword,
      newPassword: newPassword
    }

    await axios.patch(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + userLogged?.uuid, data,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      }
    )
      .then(async (response) => {
        setSuccessMsg('Profile modifié avec succès');
        // only for showing linear progress
        await delay(3000);
        setActiveLinearProgress(false);
        navigateOnBaseUserType();
      })
      .catch(async (err) => {
        // only for showing linear progress
        await delay(3000);
        setActiveLinearProgress(false);

        if ((err?.response?.status === 401) || (err?.response?.status === 403)) {
          setHttpErrorMsg('Session expirée');
          logout(); navigate('/signin');
        } else {
          setHttpErrorMsg(translate(err.response?.data.keyError));
        }
      });
  }

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  const handlePhoneNumber = (phone) => {
    setUserLogged({ ...userLogged, phoneNumber: phone })
    // matchIsValidTel(phone) ? setIsPhoneNumberValid(true) : setIsPhoneNumberValid(false);
  }

  const handlePasswordValidation = (type, passwordInputValue) => {
    if (type === 'confirmNewPassword') {
      setConfirmNewPassword(passwordInputValue);
      if (passwordInputValue !== newPassword) setIsConfirmPwError(true);
      else setIsConfirmPwError(false);

    }

    if (type === 'newPassword' || type === 'currentPassword') {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);

      if (type === 'newPassword') {
        setNewPassword(String(passwordInputValue).trim());
        setIsPwError(true);
      } else {
        setCurrentPassword(String(passwordInputValue).trim());
        setIsCurrentPwError(true);
      }

      if (passwordInputValue.length === 0) {
        type === 'newPassword' ? setPwErrorMessage("Password est vide") : setCurrentPwErrorMessage("Password est vide");
      }
      // else if (!uppercasePassword) {
      //   type === 'newPassword' ? setPwErrorMessage("Au moins un majuscule") : setCurrentPwErrorMessage("Au moins un majuscule");
      // } else if (!lowercasePassword) {
      //   type === 'newPassword' ? setPwErrorMessage("Au moins un minuscule") : setCurrentPwErrorMessage("Au moins un minuscule");
      // } else if (!digitsPassword) {
      //   type === 'newPassword' ? setPwErrorMessage("Au moins un chiffre") : setCurrentPwErrorMessage("Au moins un chiffre");
      // } else if (!specialCharPassword) {
      //   type === 'newPassword' ? setPwErrorMessage("Au moins un caractère spécial") : setCurrentPwErrorMessage("Au moins un caractère spécial");
      // } 
      else if (!minLengthPassword) {
        type === 'newPassword' ? setPwErrorMessage("Au moins 8 caractères") : setCurrentPwErrorMessage("Au moins 8 caractères");
      } else {
        if (type === 'newPassword') {
          setPwErrorMessage("");
          setIsPwError(false);
        } else {
          setCurrentPwErrorMessage("");
          setIsCurrentPwError(false);
        }
      }
    }
  }

  const handleShowPassword = (value) => {
    if (value === 'currentPassword') {
      showCurrentPassword ? setCurrentPasswordType('password') : setCurrentPasswordType('text');
      setShowCurrentPassword(v => !v);
    } else if (value === 'newPassword') {
      showNewPassword ? setNewPasswordType('password') : setNewPasswordType('text');
      setShowNewPassword(v => !v);
    } else if (value === 'confirmNewPassword') {
      showConfirmNewPassword ? setConfirmNewPasswordType('password') : setConfirmNewPasswordType('text');
      setShowConfirmNewPassword(v => !v);
    }
  }

  const navigateOnBaseUserType = () => {
    if (role === 'R') {
      navigate(`/profiles/${userLogged?.uuid}/dashboard`);
    } else if (role === 'A') {
      navigate(`/admin/dashboard`);
    }
  }

  return (
    <div>
      <AppBar sx={{ position: 'relative', mb: 6, backgroundColor: "#383c44" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => navigateOnBaseUserType()}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Profile
          </Typography>
          <Box className="flex-right">
            <Button sx={{ mr: 4 }} color="inherit" onClick={reset}>
              Réinitialiser
            </Button>
            <Button color="inherit" onClick={(e) => updateProfile(e)}>
              Sauvegarder
            </Button>
          </Box>
        </Toolbar>

        {activeLinearProgress &&
          <Box sx={{ width: '100%', position: 'absolute', backgroundColor: 'white', '& .MuiLinearProgress-bar': { backgroundColor: '#03BCA1' } }}>
            <LinearProgress />
          </Box>
        }
      </AppBar>

      {httpErrorMsg?.length > 0 && <WeinvSnackbar severity='error' horizontal='right' vertical='bottom' msg={httpErrorMsg} handleClose={setHttpErrorMsg} />}
      {successMsg?.length > 0 && <WeinvSnackbar severity='success' horizontal='right' vertical='bottom' msg={successMsg} handleClose={setSuccessMsg} />}

      <div className="vertical-center" style={{ backgroundColor: "#fff", justifyContent: "center", display: "flex" }}>
        {activeCircularProgress ? <img src={loadingGif} width={90} style={{ position: "fixed", zIndex: (theme) => theme.zIndex.drawer + 1, marginTop: 300 }} /> : null}
      </div>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "black" }}
        open={activeCircularProgress}
      >
        <CircularProgress className="color-gray" />
      </Backdrop> */}

      <WeinvProfileIcon paddingTop={0} />

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Box component="form" sx={{ "& .MuiTextField-root": { mt: 2 }, width: "100%" }} noValidate>
            {userLogged ? <>
              <TextField
                required
                autoComplete="firstname"
                className="full-width"
                sx={styles(userLogged?.firstName?.length === 0, '49%', '2%')}
                fullWidth
                id="firstname"
                label="Prénom"
                error={userLogged?.firstName?.length === 0}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={getValue(userLogged?.firstName)}
                onChange={(e) => setUserLogged({ ...userLogged, firstName: String(e.target.value).trim() })}
              />
              <TextField
                required
                autoComplete="lastname"
                className="full-width"
                sx={styles(userLogged?.lastName?.length === 0, '49%', '0')}
                fullWidth
                margin="normal"
                id="lastname"
                label="Nom"
                InputLabelProps={{ shrink: true }}
                error={userLogged?.lastName?.length === 0}
                value={getValue(userLogged?.lastName)}
                onChange={(e) => setUserLogged({ ...userLogged, lastName: String(e.target.value).trim() })}
              />
              <TextField
                required
                autoComplete="username"
                className="full-width"
                sx={styles(false, null, null)}
                fullWidth
                id="username"
                label="Identifiant"
                disabled
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={getValue(userLogged?.username)}
              />
              <TextField
                autoComplete="email"
                className="full-width"
                id="email"
                label="Email"
                sx={styles(false, '49%', '2%')}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={getValue(userLogged?.email)}
                onChange={(e) => setUserLogged({ ...userLogged, email: String(e.target.value).trim() })}
              />
              <MuiTelInput
                className="full-width"
                //sx={styles(!isPhoneNumberValid, '49%', '0')}
                sx={styles(false, '49%', '0')}
                value={userLogged?.phoneNumber ? getValue(userLogged?.phoneNumber) : '+243'}
                //error={!isPhoneNumberValid}
                onChange={handlePhoneNumber}
              />
              {/* <Box className="mt-10">
              <FormControl sx={{ mt: 1, width: '100%' }}>
                <InputLabel id="langue-label" sx={{'& inputLabel.Mui-focused': { color: '#03d388 !important' }}}>Langue</InputLabel>
                <Select
                  required
                  className="full-width"
                  sx={styles(false, null, null)}
                  labelId="langue-label"
                  id="langue"
                  value={String(userLogged?.language)}
                  defaultValue={"FR"}
                  onChange={(e) => setUserLogged({ ...userLogged, language: e.target.value })}
                  label="Langue"
                >
                  <MenuItem value={"FR"}>Français</MenuItem>
                  <MenuItem value={"EN"}>Anglais</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={<Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} inputProps={{ 'aria-label': 'controlled' }} />}
                  label="Souhaitez-vous changer de mot de passe ?"
                />
              </Box>
              {!checked ? null :
                <Box sx={{ marginBottom: 20 }}>
                  <TextField
                    required
                    fullWidth
                    sx={styles(isCurrentPwError, null, null)}
                    type={currentPasswordType}
                    margin="normal"
                    id="currentPassword"
                    label="Mot de passe actuel"
                    error={isCurrentPwError}
                    value={getValue(currentPassword)}
                    onChange={(e) => handlePasswordValidation('currentPassword', e.target.value)}
                    FormHelperTextProps={{ className: "no-margin-left no-letter-space" }}
                    helperText={!isCurrentPwError ? null :
                      <div className="OyEIQ uSvLId" jsname="h9d3hd" aria-live="polite">
                        <div className="EjBTad" aria-hidden="true">
                          <svg aria-hidden="true" className="stUf5b LxE1Id" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                          </svg>
                        </div>
                        <div jsname="B34EJ">
                          <span jsslot="">{currentPwErrorMessage}</span>
                        </div>
                      </div>
                    }
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleShowPassword('currentPassword')}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end">
                            {!showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                  <TextField
                    required
                    className="full-width"
                    sx={styles(isPwError, '49%', '2%')}
                    type={newPasswordType}
                    id="newPassword"
                    label="Nouveau mot de passe"
                    error={isPwError}
                    margin="normal"
                    value={getValue(newPassword)}
                    onChange={(e) => handlePasswordValidation('newPassword', e.target.value)}
                    FormHelperTextProps={{ className: "no-margin-left no-letter-space" }}
                    helperText={!isPwError ? null :
                      <div className="OyEIQ uSvLId" jsname="h9d3hd" aria-live="polite">
                        <div className="EjBTad" aria-hidden="true">
                          <svg aria-hidden="true" className="stUf5b LxE1Id" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                          </svg>
                        </div>
                        <div jsname="B34EJ">
                          <span jsslot="">{pwErrorMessage}</span>
                        </div>
                      </div>
                    }
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleShowPassword('newPassword')}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end">
                            {!showNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                  <TextField
                    required
                    className="full-width"
                    sx={styles(isConfirmPwError, '49%', '0')}
                    type={confirmNewPasswordType}
                    id="confirmNewPassword"
                    label="Confirmation mot de passe"
                    error={isConfirmPwError}
                    margin="normal"
                    value={getValue(confirmNewPassword)}
                    onChange={(e) => handlePasswordValidation('confirmNewPassword', e.target.value)}
                    FormHelperTextProps={{ className: "no-margin-left no-letter-space" }}
                    helperText={!isConfirmPwError ? null :
                      <div className="OyEIQ uSvLId" jsname="h9d3hd" aria-live="polite">
                        <div className="EjBTad" aria-hidden="true">
                          <svg aria-hidden="true" className="stUf5b LxE1Id" fill="currentColor" focusable="false" width="16px" height="16px" viewBox="0 0 24 24" xmlns="https://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                          </svg>
                        </div>
                        <div jsname="B34EJ">
                          <span jsslot="">Les mots de passe ne correspondent pas</span>
                        </div>
                      </div>
                    }
                    InputProps={{
                      endAdornment:
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleShowPassword('confirmNewPassword')}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end">
                            {!showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    }}
                  />
                </Box>
              }
            </> : <>
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block", marginRight: "2%" }} />
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block" }} />
              <Skeleton animation="wave" height={90} width="100%" />
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block", marginRight: "2%" }} />
              <Skeleton animation="wave" height={90} width="49%" sx={{ display: "inline-block" }} />
              <Skeleton animation="wave" height={20} width="60%" />
            </>
            }
          </Box>
        </Box>
        <FooterBar /*isColorBlu={true} color="#008ffb"*/ />
      </Container>
    </div >
  );
}

const getValue = (value) => {
  return !value ? value : String(value).trim();
}

