import React from "react";

export default function AboutSection() {
  return (
    <section
      id="section-5b0a4ba50e4e8"
      className="s123-module s123-module-about layout-10 bg-primary section-5b0a4ba50e4e8"
      data-module-id="5b0a4ba50e4e8"
      data-module-type-num="16"
    >
      <div
        data-aos="fade-up"
        className="container-fluid page_header_style noBackground aos-init aos-animate"
      >
        <div className="row">
          <div className="container moduleTitleContainer">
            <div className="row modulesTitle text-center">
              <div className="col-xs-12">
                <div className="page-header-wrap">
                  <h2
                    id="section-5b0a4ba50e4e8-title"
                    className="s123-page-header"
                  >
                    À propos
                  </h2>
                  <hr className="small" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container aos-init aos-animate" data-aos="fade-up">
        {/* Items Area */}
        <div className="row">
          <div className="col-xs-12">
            <div className="about-container text-on-top text-center preview-highlighter" style={{ fontWeight: 500 }}>
              <div className="responsive-handler fr-view breakable about-description box box-primary box-text-primary">
                L'application <strong>Weinv</strong> est fondée avec pour mission de créer des invitations numériques qui fourniraient toute l'élégance des invitations traditionnelles.
                <br />
                <br />
                Offrez directement à tous vos amis et à votre famille des invitations de mariage personnalisées par e-mail, SMS ou lien partageable.
              </div>
              <div data-ride="carousel" className="carousel slide">
                <div className="carousel-inner">
                  {/* Carousel Slides / Quotes */}
                  <div className="item active one-item">
                    <div className="about-item">
                      <div
                        className="about-image bgLazyload entered loaded about-image-specs"
                        data-bg="../assets/section-events.jpg"
                        role="img"
                        aria-label="About"
                        data-ll-status="loaded"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}