import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './dashboard.css';
import Properties from '../../configs/Properties'
import { logout } from '../../configs/Services';
import WeinvProfileIcon from '../../components/icon/WeinvProfileIcon';
import WeinvCreationIcon from '../../components/icon/WeinvCreationIcon';
import WeddingsTable from "../../components/WeddingsTable";
import ProfileAvatar from "../../components/WeinvAvatar";
import ProfileDialog from "../Profile";
import ClickAwayListener from '@mui/base/ClickAwayListener';
import DialogInviteGuest from "../../components/DialogInviteGuest";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';


export default function Dashboard() {
  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const weddingsTableChildRef = useRef();

  const navigate = useNavigate();
  const [weddings, setEvents] = useState([]);
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState();
  const [open, setOpen] = useState(false);
  const [openDialogProfile, setOpenDialogProfile] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [wedding, setEvent] = useState();
  const [userLogged, setUserLogged] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [openInviteGuestDialog, setOpenInviteGuestDialog] = React.useState(false);
  const [openLoader, setOpenLoader] = React.useState(true);
  const [openLoaderGuestTable, setOpenLoaderGuestTable] = React.useState(false);


  useEffect(() => {
    getUser();
    weddingsTableChildRef?.current?.preCallGetData(null, searchKeyword, 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!openInviteGuestDialog]);

  const getUser = async () => {
    const uuid = sessionStorage.getItem('uuidUser');

    // session expired/logout
    if (uuid === null) {
      logout(); navigate('/signin');
    }
    return await axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_USERS_PATH_BASE + uuid,
      {
        headers: {
          "content-type": "application/json",
          "X-tx-id": uuidv4(),
          "Authorization": "Bearer " + sessionStorage.getItem('access-token')
        }
      })
      .then((response) => setUserLogged(response?.data))
      .catch((err) => {
        if ((err.response?.data.status === 401) || (err.response?.data.status === 403)) {
          logout(); navigate('/signin');
        }
      });
  }

  return (<>
    <html lang="en" /*x-data="data()"*/>
      <head>
        <meta charset="utf-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#0ed3cf" />
        <meta name="msapplication-TileColor" content="#0ed3cf" />
        <meta name="theme-color" content="#0ed3cf" />

        <meta
          property="og:image"
          content="http://tailwindcomponents.com/storage/8638/conversions/temp79570-ogimage.jpg?v=2023-04-27 13:11:00"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />
        <meta property="og:image:type" content="image/png" />

        <meta
          property="og:url"
          content="https://tailwindcomponents.com/component/resposive-dashboard/landing"
        />
        <meta property="og:title" content="Dashboard by safwanAzman" />
        <meta property="og:description" content="semoga bermanfaat untuk semua" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TwComponents" />
        <meta name="twitter:title" content="Dashboard by safwanAzman" />
        <meta name="twitter:description" content="semoga bermanfaat untuk semua" />
        <meta
          name="twitter:image"
          content="http://tailwindcomponents.com/storage/8638/conversions/temp79570-ogimage.jpg?v=2023-04-27 13:11:00"
        />

        <title>Dashboard</title>

        <script src="https://cdn.tailwindcss.com"></script>

        <script src="chrome-extension://mooikfkahbdckldjjndioackbalphokd/assets/prompt.js"></script>
      </head>
      <body id="body" className="bg-gray-200">
        {weddings?.length > 0 ? null : <>
          <div
            className="fixed bottom-0 left-0 right-0 z-40 px-4 py-3 text-center text-white bg-gray-800"
          >
            <div>
              Copyright © 2024 All rights reserved -
              <span className="website-name-preview-helper"> Weinv</span>
            </div>
            <div className="clearfix upgrade-website-preview-helper">
              by nicy m. walesa <span className="blu-color">"The mainthread"</span>
            </div>
          </div>
        </>}

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* Favicon */}
        <script
          src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js"
          defer=""
        ></script>

        <div
          className="flex h-screen bg-gray-800"
        >
          {/* Desktop sidebar */}
          <aside
            className="z-20 flex-shrink-0 w-60 pl-2 overflow-y-auto bg-gray-800 md:block"
            style={{ display: "none" }}
          >
            <div>
              <div className="text-white">
                <div className="flex p-2 bg-gray-800">
                  <a
                    href="/"
                    data-is-svg="true"
                    className="logo_name logo s123-w-l-s page-scroll logoStyle_1 a-none"
                  >
                    <div>
                      <img className="svg-img" src={require("../../assets/wein-logo.png")} alt="weinv-logo" />
                    </div>
                    <span className="website-name">weinv</span>
                  </a>
                </div>
                <div className="flex justify-center">
                  <div className="">
                    <ProfileAvatar name={userLogged?.username + " " + userLogged?.lastName} />
                    <p
                      className="font-bold text-base text-gray-400 pt-2 text-center w-24"
                    >
                      {userLogged?.username}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </aside>

          {/* Mobile sidebar */}
          {/* Backdrop */}
          <div
            className="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
            style={sideMenuOpen ? { display: "block" } : { display: "none" }}
          ></div>

          <aside
            className="fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-gray-900 dark:bg-gray-800 md:hidden"
            style={sideMenuOpen ? { display: "block" } : { display: "none" }}
          >
            <div>
              <div className="text-white">
                <div className="flex p-2 bg-gray-800">
                  <div className="flex py-3 px-2 items-center">
                    <p className="text-2xl text-green-500 font-semibold">SA</p>
                    DASHBOARD
                    <p></p>
                  </div>
                </div>
                <div>
                  <ul className="mt-6 leading-10">
                    <li className="relative px-2 py-1">
                      <a
                        className="inline-flex items-center w-full text-sm font-semibold text-white transition-colors duration-150 cursor-pointer hover:text-green-500"
                        href=" #"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                          ></path>
                        </svg>
                        <span className="ml-4">DASHBOARD</span>
                      </a>
                    </li>
                    <li className="relative px-2 py-1" /*x-data="{ Open : false  }"*/>
                      <div
                        className="inline-flex items-center justify-between w-full text-base font-semibold transition-colors duration-150 text-gray-500 hover:text-yellow-400 cursor-pointer"
                        // x-on:click="Open = !Open"
                        onClick={() => setOpen(!open)}
                      >
                        <span
                          className="inline-flex items-center text-sm font-semibold text-white hover:text-green-400"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M8 4H6a2 2 0 00-2 2v12a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-2m-4-1v8m0 0l3-3m-3 3L9 8m-5 5h2.586a1 1 0 01.707.293l2.414 2.414a1 1 0 00.707.293h3.172a1 1 0 00.707-.293l2.414-2.414a1 1 0 01.707-.293H20"
                            ></path>
                          </svg>
                          <span className="ml-4">ITEM</span>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </aside>


          <div className="flex flex-col flex-1 w-full overflow-y-auto">
            <header className="z-40 py-4 bg-gray-800">
              <div className="flex items-center justify-between h-8 px-6 mx-auto">

                {/* Search Input */}
                <div className="flex justify-center mt-2 mr-4">
                  <div className="relative flex w-full flex-wrap items-stretch mb-3">
                    <input
                      type="search"
                      placeholder="Search"
                      className="form-input px-3 py-2 placeholder-gray-400 text-gray-700 relative bg-white rounded-lg text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pr-10"
                    // onChange={(e) => setSearchKeyword(e.target.value)}
                    // onKeyDown={() => weddingsTableChildRef?.current?.preCallGetData(null, searchKeyword, 1)}
                    />
                    <span
                      style={{ cursor: "pointer" }}
                      className="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 right-0 pr-3 py-3"
                    // onClick={() => weddingsTableChildRef?.current?.preCallGetData(null, searchKeyword, 1)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 -mt-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>

                <ul className="flex items-center flex-shrink-0 space-x-6">

                  {/* Profile menu */}
                  <li className="relative">
                    <button
                      className="p-2 bg-white text-green-400 align-middle rounded-full hover:text-white hover:bg-green-400 focus:outline-none"
                      onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                      aria-label="Account"
                      aria-haspopup="true"
                    >
                      <div className="flex items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                          ></path>
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                          ></path>
                        </svg>
                      </div>
                    </button>

                    {!profileMenuOpen ? null : <>
                      <ClickAwayListener onClickAway={() => setProfileMenuOpen(false)}>
                        <div>
                          <ul
                            onClick={() => setProfileMenuOpen(false)}
                            className="absolute right-0 w-56 p-2 mt-2 space-y-2 text-gray-600 profile-btn border border-green-500 rounded-md shadow-md"
                            aria-label="submenu"
                          >
                            <li className="flex">
                              <a
                                className="text-white inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                                href={"/"}
                              >
                                <HomeOutlinedIcon sx={{ marginRight: 1 }} />
                                <span>Home</span>
                              </a>
                            </li>
                            <li className="flex">
                              <a
                                className="text-white inline-flex items-center w-full px-2 py-1 text-sm font-semibold transition-colors duration-150 rounded-md hover:bg-gray-100 hover:text-gray-800"
                                href="#"
                                onClick={() => { logout(); navigate('/signin') }}
                              >
                                <ExitToAppOutlinedIcon sx={{ marginRight: 1 }} />
                                <span>Déconnexion</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </ClickAwayListener>
                    </>}
                  </li>
                </ul >
              </div >
            </header >
            <main className="">
              <div
                className="grid mb-4 pb-10 px-8 mx-4 rounded-3xl bg-gray-100 border-4 border-green-400"
              >
                <div className="grid grid-cols-8 gap-3">
                  <div className="grid grid-cols-8 col-span-12 gap-3 xxl:col-span-9">
                    <div className="col-span-12 mt-8">
                      <div className="flex items-center h-10 intro-y">
                        <h2 className="mr-5 text-lg font-medium truncate">Dashboard</h2>
                      </div>
                      <div className="grid grid-cols-8 gap-3 mt-5">
                        <a href="#" className="card shadow-xl rounded-lg col-span-12 sm:col-span-4 xl:col-span-4 intro-y" onClick={() => setOpenInviteGuestDialog(true)} >
                          <WeinvCreationIcon />
                          <p className="a-c">Créer un organisateur</p>
                        </a>
                        {openInviteGuestDialog &&
                          <DialogInviteGuest
                            userLogged={userLogged}
                            openInviteGuestDialog={openInviteGuestDialog}
                            setOpenInviteGuestDialog={setOpenInviteGuestDialog}
                            weddingsTableChildRef={weddingsTableChildRef}
                            title={'Créer un organisateur'}
                            isForResponsible={true}
                            btnAction={'Créer'}
                          />
                        }
                        <a href="#" onClick={() => navigate(`/profiles/${userLogged?.uuid}/infos`)} className="card shadow-xl rounded-lg col-span-12 sm:col-span-4 xl:col-span-4 intro-y">
                          <WeinvProfileIcon paddingTop={"10px"}/>
                          <p className="a-c">Profile</p>
                        </a>
                        {!openDialogProfile ? null :
                          <ProfileDialog
                            userLogged={userLogged}
                            setOpenDialogProfile={setOpenDialogProfile}
                          />
                        }
                      </div>
                    </div>
                    <div className="col-span-12 mt-5">

                      <WeddingsTable
                        ref={weddingsTableChildRef}
                        weddings={weddings}
                        setEvents={setEvents}
                        openLoaderGuestTable={openLoaderGuestTable}
                        setOpenLoaderGuestTable={setOpenLoaderGuestTable}
                        openLoaderParent={openLoader} />
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div >
        </div >
      </body >
    </html >
  </>);
}
