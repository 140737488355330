import Properties from "./Properties";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { WhatsappShareButton, WhatsappIcon } from 'react-share';


export function translate(keyError) {
    if (keyError === 'USER_NOT_FOUND' || keyError === 'WRONG_USERNAME') return 'Impossible de trouver l’utilisateur sélectionné';
    else if (keyError === 'EVENT_NOT_FOUND') return 'Aucune configuration de mariage trouvée';
    else if (keyError === 'USER_ALREADY_RESPONSIBLE') return 'L’utilisateur est déjà organisateur d’un autre événement';
    else if (keyError === 'WRONG_PASSWORD') return "Mot de passe incorrect. Réessayer ou contacter l'administrateur pour le réinitialiser";
    else if (keyError === 'INVALID_EVENT_DATE') return 'La date du mariage ne doit pas être égale ou antérieure à la date du jour';
    else if (keyError === 'WRONG_USERNAME_OR_PWD') return 'Nom d’utilisateur ou mot de passe incorrect';
    else if (keyError === 'VALIDATION_ERROR') return 'Champs obligatoires manquants';
    //else if (keyError && ('' + keyError).toLowerCase().includes('invalid phone number')) return 'Numéro de téléphone non valide';
    else if (keyError === 'USER_ALREADY_EXISTS') return 'Il y a déjà un utilisateur avec cet identifiant';
    else if (keyError === 'MAX_INVITATION_NUMBER_REACHED') return 'Le nombre maximum d’invitations atteint';
    else if (keyError === 'INCORRECT_TABLE_NUMBER') return 'Numéro de table est incorrect';
    else return "Désolé, nous ne pouvons pas donner suite à votre demande";
}

export function logout(e) {
    e?.preventDefault();
    sessionStorage.removeItem('type');
    sessionStorage.removeItem('userLoggedDenomination');
    sessionStorage.removeItem("roles");
    sessionStorage.removeItem("uuidUser");
    sessionStorage.removeItem("uuidEvent");
    sessionStorage.removeItem("access-token");
    sessionStorage.removeItem("refresh-token");
    sessionStorage.removeItem('badge-content-n-notifications');
    sessionStorage.removeItem("badge-content-n-messages");
}

export function whatsappMsg(input) {
    const userLoggedDenomination = input?.firstName + "_" + input?.lastName;
    if (input?.isForResponsible) {
        return Properties.WEINV_SHARE_RESPONSIBLE_DATA_TO_WHATSAPP
            .replace("<weinv_ui_home>", Properties.WEINV_UI_HOME)
            .replace("<username>", input?.username)
            .replace("<password>", input?.password);
    } else if (input?.userLogged?.type === 'WEDDING' && input?.totalInvitations > 1) {
        return Properties.WEINV_SHARE_TO_WHATSAPP_TYPE_WEDDING_FAMILY
            .replace("<firstName>", input?.firstName)
            .replace("<lastName>", input?.lastName)
            .replace("<category>", getWeddingCategory(input?.wedding?.weddingCategory))
            .replace("<husbandName>", input?.wedding?.husbandName)
            .replace("<wifeName>", input?.wedding?.wifeName)
            .replace("<totalInvitations>", input?.totalInvitations)
            .replace("<weinv_ui_home>", Properties.WEINV_UI_HOME + "wedding/invitations/uuid?uk=" + input?.username + "&pv=invitation&name=" + userLoggedDenomination)
            .replace("<username>", input?.username)
            .replace("<password>", input?.password);
    } else if (input?.userLogged?.type === 'WEDDING' && input?.totalInvitations === 1) {
        return Properties.WEINV_SHARE_TO_WHATSAPP_TYPE_WEDDING
            .replace("<firstName>", input?.firstName)
            .replace("<lastName>", input?.lastName)
            .replace("<category>", getWeddingCategory(input?.wedding?.weddingCategory))
            .replace("<husbandName>", input?.wedding?.husbandName)
            .replace("<wifeName>", input?.wedding?.wifeName)
            .replace("<weinv_ui_home>", Properties.WEINV_UI_HOME + "wedding/invitations/uuid?uk=" + input?.username + "&pv=invitation&name=" + userLoggedDenomination);
        //         .replace("<username>", username)
        //         .replace("<password>", password);
    } else {
        return Properties.WEINV_SHARE_TO_WHATSAPP_TYPE_OTHERS
            .replace("<firstName>", input?.firstName)
            .replace("<lastName>", input?.lastName)
            .replace("<eventName>", input?.wedding?.eventName)
            .replace("<wifeName>", input?.wedding?.wifeName)
            .replace("<weinv_ui_home>", Properties.WEINV_UI_HOME + "wedding/invitations/uuid?uk=" + input?.username + "&pv=invitation&name=" + userLoggedDenomination)
            .replace("<username>", input?.username)
            .replace("<password>", input?.password);
    }
}

export function getWeddingCategory(weddingCategory) {
    if (weddingCategory == 'RELIGIOUS') return 'religieux';
    else if (weddingCategory == 'CIVIL') return 'civil';
    else if (weddingCategory == 'TRADITIONAL') return 'coutumier';
    else return '';
}


export function getEventImage() {
    const uuidEvent = sessionStorage.getItem('uuidEvent');
    return axios.get(Properties.WEINV_BE_API_BASE + Properties.WEINV_EVENTS_PATH_BASE + uuidEvent + "/image",
        {
            headers: {
                "content-type": "application/json",
                "X-tx-id": uuidv4(),
                "Authorization": "Bearer " + sessionStorage.getItem('access-token')
            }
        })
        .then((response) => response.data)
        .catch((err) => { console.log(err) });
}


export const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto"
    }
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch"
            }
        }
    }
}));


export const whatsappShare = (input) => (
    <WhatsappShareButton
        url={whatsappMsg(input)}
        className="btn btn-outline-success"
    >
        <small style={{ marginRight: 10 }}>{input?.text}</small><br />
        <WhatsappIcon size={70} round iconFillColor="green" bgStyle={{ fill: "white" }} />
    </WhatsappShareButton>
);