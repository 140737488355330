import React from "react";


export default function BouncedLoader() {

  return (<>
    <div>
        <div class="loadme-fadingCircle">
            <div class="loadme-fadingCircle-child1 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child2 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child3 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child4 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child5 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child6 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child7 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child8 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child9 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child10 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child11 loadme-fadingCircle-child"></div>
            <div class="loadme-fadingCircle-child12 loadme-fadingCircle-child"></div>
        </div>
        <div class="loadme-mask"></div>
    </div>
  </>);

}